import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Skeleton } from "@mui/material";
import isUndefined from "lodash/isUndefined";
import { useHasMasterRights } from "../../../utils/useHasMasterRights";
import { getPayDate, getTotal } from "../../../utils/order";
import { usePayment } from "../../../utils/usePayment";
import EuroIcon from "@mui/icons-material/EuroRounded";
import { useQueryClient } from "react-query";
import { PaymentIcon } from "./PaymentIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const PaymentCard = ({
  order,
  label,
  shouldBePaid,
  canSendMail,
  parentOrder,
  paidStatus = "processing",
}) => {
  const classes = useStyles();
  const hasMasterRights = useHasMasterRights();
  const date = getPayDate(order);
  const queryClient = useQueryClient();

  const builtPaidStatus = hasMasterRights ? paidStatus : "on-validation";

  let displayPaidButton =
    order &&
    (shouldBePaid ||
      (isUndefined(shouldBePaid) &&
        order.status !== "processing" &&
        order.status !== "completed"));

  if (!hasMasterRights && order?.status === "on-validation") {
    displayPaidButton = false;
  }

  const handlePayment = usePayment(
    { order, paidStatus: builtPaidStatus, parentOrder },
    {
      set: (value) => queryClient.setQueryData(["order", order.id], value),
      cancel: () => queryClient.cancelQueries(["order", order.id]),
      modify: (oldOrder) => {
        if (!hasMasterRights) {
          oldOrder.status = "on-validation";
        } else if (oldOrder.parent_id) {
          oldOrder.status = "completed";
        } else {
          oldOrder.status = "processing";
        }

        oldOrder.date_paid = Date.now();

        return oldOrder;
      },
      getPrevious: () => queryClient.getQueryData(["order", order.id]),
      invalidate: (id) => {
        setTimeout(() => {
          queryClient.invalidateQueries(["order", id]);
          queryClient.invalidateQueries("member");
        }, 5000);
      },
    },
    [hasMasterRights, queryClient, paidStatus]
  );

  const notPaid = order?.status === "pending" || order?.status === "on-hold";
  const onValidation = order?.status === "on-validation";

  return (
    <Grid item xs={12} md={6}>
      <Card className={classes.root}>
        <CardContent>
          {order ? (
            <>
              <Box display="flex">
                <Typography variant="body2" gutterBottom>
                  {label ? label : "Paiement unique"}
                </Typography>
                <PaymentIcon status={order?.status} />
              </Box>
              <Typography variant="body1">{getTotal(order)}€</Typography>
            </>
          ) : (
            <>
              <Skeleton width={90} />
              <Skeleton width={50} height={30} />
              <Skeleton width={120} />
            </>
          )}
          {!notPaid && !onValidation && (
            <>
              {date ? (
                <Typography color="textSecondary" variant="body2">
                  payé le {date}
                </Typography>
              ) : null}
              {order?.payment_method_title && (
                <Typography color="textSecondary" variant="body2">
                  par {order.payment_method_title.toLowerCase()}
                </Typography>
              )}
            </>
          )}
          {notPaid && (
            <Typography color="textSecondary" variant="body2">
              en attente
            </Typography>
          )}
          {onValidation && (
            <Typography color="textSecondary" variant="body2">
              en attente de validation
            </Typography>
          )}
        </CardContent>
        {displayPaidButton && (
          <CardActions>
            <Button
              size="small"
              variant="contained"
              startIcon={<EuroIcon />}
              color="secondary"
              onClick={handlePayment}
            >
              Paiement reçu
            </Button>
            {false && canSendMail && (
              <Button size="small" variant="outlined">
                Envoyer un rappel
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};
