import { split } from "lodash";
import get from "lodash/get";
import some from "lodash/some";
import upperFirst from "lodash/upperFirst";
import isUndefined from "lodash/isUndefined";

export const documents = {
  member: {
    certificate: { label: "Certificat médical" },
    permission: { label: "Autorisation parentale" },
  },
  nursery: {
    certificate: { label: "Certificat médical", iterative: true },
    permission: { label: "Autorisation parentale", iterative: true },
    authorization: { label: "Agrément" },
  },
};

export const formatName = (firstName = "", lastName = "") =>
  `${upperFirst(firstName)} ${lastName.toUpperCase()}`;

export const getDocumentKey = (key, index) =>
  `_${key}_${!isUndefined(index) ? `${index}_` : ""}hand_delivered`;

const getAge = (date) => {
  var diff = Date.now() - date.getTime();
  var age = new Date(diff);

  return Math.abs(age.getUTCFullYear() - 1970);
};

export const isDocumentRequired = (key, member) => {
  if (key !== "permission") {
    return true;
  }

  const birthday = split(member["Date De Naissance"], "/");

  return getAge(new Date(birthday[2], birthday[1], birthday[0])) < 18;
};

const isDocumentDelivered = (member) => (props, key) => {
  if (props.iterative) {
    const numberOfChildren = parseInt(get(member, "Nombre D'enfants"), 10);
    const iterations = new Array(numberOfChildren).fill(0);

    return iterations.some(
      (value, index) =>
        !member[getDocumentKey(key, index)] &&
        !get(member, ["files", key, index])
    );
  }

  return (
    isDocumentRequired(key, member) &&
    !member[getDocumentKey(key)] &&
    !get(member, ["files", key])
  );
};

export const getHasUndeliveredDocument = (member, isNursery) =>
  member
    ? some(
        documents[isNursery ? "nursery" : "member"],
        isDocumentDelivered(member)
      )
    : false;

export const shouldRequestCard = (member) => !!member["Licence"];

export const formatNumberOfChildren = (numberOfChildren) =>
  `${numberOfChildren} enfant${parseInt(numberOfChildren, 10) > 1 ? "s" : ""}`;
