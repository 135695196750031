import React from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { AccessoryCard } from "./AccessoryCard";

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontStyle: "italic",
  paddingLeft: theme.spacing(1),
}));

export const MemberAccessories = ({ member, memberData }) => {
  let content;
  if (isEmpty(member.accessories)) {
    content = <Text>Aucun</Text>;
  } else if (!memberData) {
    content = <AccessoryCard />;
  } else {
    content = (
      <Grid container spacing={1}>
        {map(memberData.accessories, (order) => (
          <AccessoryCard key={order.id} order={order} member={member} />
        ))}
      </Grid>
    );
  }

  return <Box m={1}>{content}</Box>;
};
