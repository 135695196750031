import React, { useCallback } from "react";
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import { setMemberDocument } from "../../../utils/apiFetcher";
import { useChecked } from "../../../utils/useChecked";

const useStyles = makeStyles({
  root: {
    minHeight: "50px",
  },
  icon: {
    minWidth: "auto",
  },
});

export const CheckListItem = ({
  id,
  index,
  memberId,
  label,
  handDelivered,
  url,
}) => {
  const classes = useStyles();
  const callFunction = useCallback(
    (handDelivered) =>
      setMemberDocument({
        id: memberId,
        key: id,
        handDelivered,
        index,
      }),
    [id, index, memberId]
  );
  const [checked, handleToggle] = useChecked(
    !!handDelivered || !!url,
    callFunction,
    url
  );

  const handleFile = useCallback(() => window.open(url, "_blank"), [url]);

  return (
    <ListItem className={classes.root} dense button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        <Checkbox
          checked={checked}
          disabled={!!url}
          edge="start"
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": { id } }}
        />
      </ListItemIcon>
      <ListItemText id={id} primary={label} />
      {url && (
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="fichier"
            onClick={handleFile}
            size="large"
          >
            <DescriptionRoundedIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
