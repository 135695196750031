import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

export const getTotal = (order) => {
  if (!order) {
    return;
  }

  let total = Number(order.total);
  if (order && !isEmpty(order.refunds)) {
    const refundTotal = order.refunds.reduce(
      (total, refund) => total + Number(refund.total),
      0
    );

    total = Number(order.total) + refundTotal;
  }

  return total.toFixed(2);
};

export const hasDeposit = (order) =>
  order &&
  find(
    order.meta_data,
    (meta) =>
      meta.key === "_wc_deposits_order_has_deposit" && meta.value === "yes"
  );

export const getPayDate = (order) => {
  if (!order) {
    return;
  }

  let date;
  if (order.date_paid) {
    const jsDate = new Date(order.date_paid);
    date = new Intl.DateTimeFormat("fr-FR").format(jsDate);
  }

  return date;
};
