import { RESET_ERROR, THROW_ERROR } from "./actions";

const initialState = {
  error: false,
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case THROW_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: false,
      };
    }
    default:
      return state;
  }
};
