import React, { forwardRef } from "react";
import { SectionTitle } from "../SectionTitle";
import { Payment } from "./Payment";
import { RegistrationTable } from "./RegistrationTable";

export const MemberFinancialDetails = forwardRef(({ order, ...rest }, ref) => (
  <div ref={ref} {...rest}>
    <SectionTitle>Inscriptions liées</SectionTitle>
    <RegistrationTable order={order} />
    <SectionTitle>Paiements</SectionTitle>
    <Payment order={order} />
  </div>
));
