import React, { Fragment } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/styles";
import upperFirst from "lodash/upperFirst";
import { MembersTable } from "../member/MembersTable";
import { Message } from "../Message";
import { fetchMembers } from "../../utils/apiFetcher";
import { useQuery } from "react-query";
import { formatCourseTime } from "../../utils/course";
import { useTableStyles } from "./useTableStyles";
import { TableData } from "./TableData";
import { useParams } from "react-router-dom";

const CoursePlace = styled(Typography)({
  flex: 1,
});

export const CoursesTable = ({ activity, quarter, isNursery }) => {
  const { season } = useParams();
  const { data: members } = useQuery(
    ["members", season, activity, quarter],
    () => fetchMembers(season, activity, quarter)
  );

  const classes = useTableStyles();

  if (!members.courses) {
    return <Message>Aucun cours...</Message>;
  }

  return (
    <>
      {members.courses.map((course) => (
        <Fragment key={course.id}>
          <TableData className={classes.header}>
            <TableData>{upperFirst(course.day)}</TableData>
            {!JSON.parse(course.allout) && (
              <>
                <TableData>{course.label}</TableData>
                <TableData>{formatCourseTime(course)}</TableData>
                <TableData>
                  {upperFirst(course.educator1)}
                  {course.educator2 ? `-${upperFirst(course.educator2)}` : ""}
                </TableData>
                <CoursePlace variant="caption">{course.place}</CoursePlace>
              </>
            )}
          </TableData>
          <MembersTable
            members={members.membersByCourse[course.id]}
            isNursery={isNursery}
            isAnnual
          />
        </Fragment>
      ))}
    </>
  );
};
