import React from "react";
import { Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  new: {
    color: theme.palette.info.main,
  },
}));

export const NewMemberIcon = ({ isNew }) => {
  const classes = useStyles();

  if (!isNew) {
    return null;
  }

  return (
    <Tooltip title="nouvel adhérent" arrow placement="left">
      <FiberNewRoundedIcon
        color="action"
        classes={{
          root: classes.root,
          colorAction: classes.new,
        }}
      />
    </Tooltip>
  );
};
