import React, { useState, useCallback, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import deburr from "lodash/deburr";
import { Message } from "../Message";
import { MemberModal } from "./MemberModal";
import { MemberName } from "./MemberName";
import { PaymentIcon } from "./PaymentIcon";
import { NewMemberIcon } from "./NewMemberIcon";
import { CompetitorIcon } from "./CompetitorIcon";
import {
  formatNumberOfChildren,
  getHasUndeliveredDocument,
} from "../../utils/member";
import { MemberBadge } from "./MemberBadge";
import isEmpty from "lodash/isEmpty";

const MoodBadIconStyled = styled(MoodBadIcon)({
  verticalAlign: "middle",
});

const useStyles = makeStyles((theme) => ({
  count: {
    width: "6%",
  },
  name: {
    width: "65%",
  },
  details: {
    width: "25%",
  },
}));

export const MembersTable = ({
  members,
  noCount,
  isAnnual,
  isNursery,
  sort,
  size,
  renderAdditionalInfo,
}) => {
  const classes = useStyles();
  const [currentMember, setCurrentMember] = useState(null);

  const handleModalOpen = useCallback(
    (member) => {
      setCurrentMember(member);
    },
    [setCurrentMember]
  );

  useEffect(() => {
    if (currentMember) {
      const member = find(members, {
        registration_id: currentMember.registration_id,
      });
      if (member) {
        setCurrentMember(member);
      }
    }
  }, [currentMember, members]);

  const handleModalClose = useCallback(() => {
    setCurrentMember(null);
  }, [setCurrentMember]);

  if (isEmpty(members)) {
    return (
      <Message>
        Personne ici <MoodBadIconStyled />
      </Message>
    );
  }

  const sortedMembers = sort
    ? sortBy(members, (member) => deburr(`${member["Prénom"]} ${member.Nom}`))
    : members;

  return (
    <>
      <TableContainer>
        <Table size={size} aria-label="members">
          <TableBody>
            {map(sortedMembers, (member, index) => {
              const birthday = get(member, "Date De Naissance", "").split("/");
              const numberOfChildren = get(member, "Nombre D'enfants");
              const name = <MemberName variant="body2" member={member} />;

              let details;
              if (birthday.length > 1) {
                details = `${birthday[1]}/${birthday[2]}`;
              } else if (numberOfChildren) {
                details = formatNumberOfChildren(numberOfChildren);
              }

              return (
                <TableRow
                  key={member.registration_id}
                  onClick={() => handleModalOpen(member)}
                  hover
                >
                  {!noCount && (
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.count}
                    >
                      {index + 1}
                    </TableCell>
                  )}
                  <TableCell className={classes.name}>
                    {isAnnual ? (
                      <MemberBadge
                        color={
                          member.accessories === "on-hold"
                            ? "primary"
                            : "secondary"
                        }
                        variant="dot"
                        invisible={
                          !getHasUndeliveredDocument(member, isNursery) &&
                          member.accessories !== "on-hold"
                        }
                      >
                        {name}
                      </MemberBadge>
                    ) : (
                      name
                    )}
                  </TableCell>
                  {renderAdditionalInfo && (
                    <TableCell padding="none">
                      {renderAdditionalInfo(member)}
                    </TableCell>
                  )}
                  <TableCell padding="none">
                    <PaymentIcon status={member.payment_status} />
                    <NewMemberIcon isNew={member.new} />
                    <CompetitorIcon permit={member.Licence} />
                  </TableCell>
                  <TableCell className={classes.details}>{details}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <MemberModal
        member={currentMember}
        isAnnual={isAnnual}
        isNursery={isNursery}
        handleClose={handleModalClose}
      />
    </>
  );
};
