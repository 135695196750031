import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchCourses } from "../utils/apiFetcher";
import { useParams } from "react-router-dom";

import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import map from "lodash/map";
import uniq from "lodash/uniq";
import flattenDeep from "lodash/flattenDeep";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import union from "lodash/union";
import mapValues from "lodash/mapValues";
import fromPairs from "lodash/fromPairs";
import toPairs from "lodash/toPairs";
import isNaN from "lodash/isNaN";
import toNumber from "lodash/toNumber";
import words from "lodash/words";

import { Box, Typography } from "@mui/material";
import { ScheduleByActivity } from "../components/schedule/ScheduleByActivity";
import { format } from "date-fns";
import { doc } from "prettier";

const extractDays = (courses) =>
  sortBy(
    uniq(
      flattenDeep(
        map(values(courses), (coursesByType) =>
          map(values(coursesByType), (courses) => map(courses, "day"))
        )
      )
    ),
    (day) => {
      switch (day) {
        case "lundi":
          return 1;
        case "mardi":
          return 2;
        case "mercredi":
          return 3;
        case "jeudi":
          return 4;
        case "vendredi":
          return 5;
        case "samedi":
          return 6;
        case "dimanche":
          return 7;
        default:
          return 0;
      }
    }
  );

const acroId = 8905;
const babyId = 9775;
const fitnessId = 9792;
const parentChildLabel = "Parent-Enfant";
const babyLabel = "Bambins à ressorts";

export const Schedule = ({ setHeaderVisible }) => {
  const { season } = useParams();
  const [days, setDays] = useState([]);
  const [title, setPageTitle] = useState("");

  const { data: acroCourses } = useQuery(
    ["scheduleByProduct", season, acroId],
    async () => {
      const courses = await fetchCourses(season, acroId);

      const result = mapValues(groupBy(courses, "activity"), (coursesByType) =>
        fromPairs(
          sortBy(toPairs(groupBy(coursesByType, "label")), (course) => {
            const firstWord = words(course[0])[0];
            const age = toNumber(firstWord);
            if (!isNaN(age)) {
              return `AA${String.fromCharCode(97 + age)}`;
            } else if (course[0] === "Compétition") {
              return "z";
            } else if (
              firstWord === "Kangourou" ||
              firstWord === "Découverte"
            ) {
              return `AA${firstWord}`;
            } else {
              return course[0];
            }
          })
        )
      );

      return fromPairs(
        sortBy(toPairs(result), (course) => {
          switch (course[0]) {
            case "école acrobatique":
              return 1;
            case "gymnastique":
              return 2;
            case "trampoline":
              return 3;
            default:
              return 0;
          }
        })
      );
    },
    { suspense: false, staleTime: Infinity }
  );

  const { data: babyCourses } = useQuery(
    ["scheduleByProduct", season, babyId],
    async () => {
      const courses = await fetchCourses(season, babyId);

      const babyGroups = groupBy(courses, "label");

      return reduce(
        babyGroups,
        (acc, group, label) => {
          if (label === "8 mois - 2 ans") {
            acc[parentChildLabel][label] = group;
          } else {
            acc[babyLabel][label] = group;
          }

          return acc;
        },
        { [`${parentChildLabel}`]: {}, [`${babyLabel}`]: {} }
      );
    },
    { suspense: false, staleTime: Infinity }
  );

  const { data: fitnessCourses } = useQuery(
    ["scheduleByProduct", season, fitnessId],
    async () => {
      const courses = await fetchCourses(season, fitnessId);

      return mapValues(groupBy(courses, "activity"), (coursesByType) =>
        groupBy(coursesByType, "label")
      );
    },
    { suspense: false, staleTime: Infinity }
  );

  useEffect(() => {
    setHeaderVisible(false);
    setPageTitle(`Planning ${season}-${parseInt(season) + 1}`);
  }, [setHeaderVisible, season]);

  useEffect(() => {
    document.title = `CISAG - ${title}`;
  }, [title]);

  useEffect(() => {
    const acroDays = extractDays(acroCourses);

    const fitnessDays = extractDays(fitnessCourses);

    const babyDays = extractDays(babyCourses);

    setDays(union(acroDays, fitnessDays, babyDays));
  }, [acroCourses, babyCourses, fitnessCourses]);

  return (
    <Box m={1}>
      <Box
        sx={{
          display: "flex",
          padding: "0 20px",
        }}
      >
        <Box>
          <img
            width="50%"
            alt="CISAG"
            src="https://www.cisag.org/wp-content/uploads/2019/01/cisag-logo-vert-contourx2.png"
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" align="right">
            {title}
          </Typography>
        </Box>
      </Box>
      <ScheduleByActivity
        activityCourses={babyCourses}
        days={days}
        color="#d3f8e2"
      />
      <ScheduleByActivity
        activityCourses={acroCourses}
        days={days}
        color="#cdedfd"
      />
      <ScheduleByActivity
        activityCourses={fitnessCourses}
        days={days}
        color="#ede7b1"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
        }}
      >
        <Typography variant="body2" align="center">
          www.cisag.org - cisag@cisag.org - 06.14.25.59.35
        </Typography>
        <Typography
          variant="caption"
          align="center"
          fontStyle="italic"
          component="p"
        >
          version du {format(new Date(), "dd/MM/yyyy")}
        </Typography>
      </Box>
    </Box>
  );
};
