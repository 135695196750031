import React from "react";
import reduce from "lodash/reduce";
import { Skeleton } from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getTotal } from "../../../utils/order";
import { BillAdditionalRow } from "./BillAdditionalRow";
import { RegistrationRow } from "./RegistrationRow";

const useStyles = makeStyles((theme) => ({
  reference: {
    fontStyle: "italic",
    padding: theme.spacing(0, 2),
  },
  table: {
    backgroundColor: theme.palette.grey[100],
    margin: theme.spacing(0, 1),
    width: "auto",
  },
  total: {
    backgroundColor: theme.palette.grey[300],
    fontWeight: theme.typography.fontWeightMedium,
  },
  skeleton: {
    display: "inline-block",
  },
}));

export const RegistrationTable = ({ order }) => {
  const classes = useStyles();

  let date;
  if (order) {
    const jsDate = new Date(order.date_created);
    date = new Intl.DateTimeFormat("fr-FR").format(jsDate);
  }

  let couponDiscount = 0;
  if (order?.coupon_lines) {
    couponDiscount = reduce(
      order.coupon_lines,
      (sum, { discount }) => sum + discount,
      0
    );
  }

  return (
    <>
      <div className={classes.reference}>
        {order ? (
          <Typography variant="caption" color="textSecondary">
            Ref {order.id} - {date}
          </Typography>
        ) : (
          <Skeleton width={100} />
        )}
      </div>
      <TableContainer component={Paper} className={classes.table}>
        <Table>
          <TableBody>
            {order &&
              order.line_items.map((item) => (
                <RegistrationRow key={item.id} item={item} />
              ))}
            {!order && (
              <TableRow>
                <TableCell>
                  <Skeleton />
                  <Skeleton width={120} />
                  <Skeleton width={200} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton className={classes.skeleton} width={50} />
                </TableCell>
              </TableRow>
            )}
            <BillAdditionalRow
              items={order?.fee_lines}
              labelKey="name"
              amountKey="total"
            />
            <BillAdditionalRow
              items={order?.coupon_lines}
              labelKey="code"
              amountKey="discount"
              toSubtract
              labelPrefix="Code"
            />
            <BillAdditionalRow
              items={order?.refunds}
              labelKey="reason"
              amountKey="total"
            />
            {order && Number(order.discount_total) > couponDiscount && (
              <TableRow>
                <TableCell size="small" align="right">
                  Remise
                </TableCell>
                <TableCell size="small" align="right">
                  {(couponDiscount - Number(order.discount_total)).toFixed(2)}€
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell className={classes.total} size="small" align="right">
                TOTAL
              </TableCell>
              <TableCell className={classes.total} size="small" align="right">
                {order ? (
                  `${getTotal(order)}€`
                ) : (
                  <Skeleton className={classes.skeleton} width={50} />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
