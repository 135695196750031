const NURSERY_QUARTER_ENABLED_SEASONS = ["2021"];

export const getCurrentSeason = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  if (currentMonth >= 0 && currentMonth <= 5) {
    return currentYear - 1;
  }

  return currentYear;
};

const startingSeason = 2020;

export const getSeasonList = () => {
  let finalSeason = getCurrentSeason();
  const currentMonth = new Date().getMonth();

  if (currentMonth >= 4 && currentMonth < 6) {
    finalSeason++;
  }

  const size = finalSeason - startingSeason + 1;

  return [...Array(size).keys()].map((i) => i + startingSeason);
};

export const isNurseryQuarterEnable = (season) => {
  return NURSERY_QUARTER_ENABLED_SEASONS.indexOf(season) !== -1;
};
