import React, { useEffect, Suspense } from "react";
import { useQueryClient } from "react-query";
import map from "lodash/map";
import upperFirst from "lodash/upperFirst";
import { Loader } from "../components/Loader";
import { CoursesTable } from "../components/table/CoursesTable";
import { fetchMembers } from "../utils/apiFetcher";
import { useParams, withRouter } from "react-router-dom";
import { isNurseryQuarterEnable } from "../utils/season";

const quarterMap = {
  t4: {
    label: "4ème trimestre",
    to: "/assistantes-maternelles/t4",
  },
  t1: {
    label: "1er trimestre",
    to: "/assistantes-maternelles/t1",
  },
  t2: {
    label: "2ème trimestre",
    to: "/assistantes-maternelles/t2",
  },
};

const activity = "assistantes maternelles";

export const Nursery = withRouter(({ setPageTitle, setMoreMenu, history }) => {
  const { quarter, season } = useParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isNurseryQuarterEnable(season) && !quarter) {
      history.replace(`/${season}/assistantes-maternelles/t4`);
    } else if (!isNurseryQuarterEnable(season) && quarter) {
      history.replace(`/${season}/assistantes-maternelles/`);
    }
  }, [history, quarter, season]);

  useEffect(() => {
    if (isNurseryQuarterEnable(season)) {
      const handleMenuClick = (quarter) => () => {
        queryClient.prefetchQuery(["members", season, activity], () =>
          fetchMembers(season, activity, quarter)
        );
      };
      setPageTitle(`Assistantes maternelles - ${upperFirst(quarter)}`);
      setMoreMenu(
        map(quarterMap, (quarterMenu, quarterKey) => ({
          ...quarterMenu,
          to: `/${season}${quarterMenu.to}`,
          handleClick: handleMenuClick(quarterKey),
        }))
      );
    } else {
      setPageTitle("Assistantes maternelles");
    }

    return () => setMoreMenu(null);
  }, [quarter, queryClient, season, setMoreMenu, setPageTitle]);

  return (
    <Suspense fallback={<Loader />}>
      <CoursesTable activity={activity} quarter={quarter} isNursery />
    </Suspense>
  );
});
