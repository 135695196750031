import React, { Fragment } from "react";
import { SectionTitle } from "../SectionTitle";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  styled,
} from "@mui/material";
import filter from "lodash/filter";
import { NurseryIcon } from "../NurseryIcon";

const CampPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));

const NurseryBox = styled("span")({
  display: "flex",
});

const NurseryItemBox = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingRight: theme.spacing(1),
}));

const timeOfTheDayRegexp = /(journée|matin|après-midi)/;

const getNurseryInfo = (nurseryDates, date) => {
  const nurseryTimes = filter(
    nurseryDates,
    (nurseryDate) =>
      nurseryDate.replace(timeOfTheDayRegexp, "") ===
      date.replace(timeOfTheDayRegexp, "")
  );

  if (nurseryTimes.length === 0) {
    return null;
  }

  const parsedNurseryTimes = nurseryTimes
    .map((nurseryTime) => {
      const nurseryTimeMatch = nurseryTime.match(timeOfTheDayRegexp);
      if (nurseryTimeMatch) {
        return nurseryTimeMatch[0];
      }

      return null;
    })
    .reduce((finalNurseryTimes, nurseryTime) => {
      if (nurseryTime === "journée") {
        finalNurseryTimes.push(
          <NurseryItemBox key="matin">
            <NurseryIcon />
            matin
          </NurseryItemBox>,
          <NurseryItemBox key="après-midi">
            <NurseryIcon />
            après-midi
          </NurseryItemBox>
        );
      } else {
        finalNurseryTimes.push(
          <NurseryItemBox key={nurseryTime}>
            <NurseryIcon />
            {nurseryTime}
          </NurseryItemBox>
        );
      }

      return finalNurseryTimes;
    }, []);

  return parsedNurseryTimes;
};

export const MemberCampList = ({ campName, dates, nurseryDates }) => {
  return (
    <>
      <SectionTitle>{campName}</SectionTitle>
      <Box m={1}>
        <CampPaper elevation={1}>
          <List dense>
            {dates.map((date, index) => (
              <Fragment key={date}>
                <ListItem>
                  <ListItemText
                    primary={date}
                    secondary={
                      <NurseryBox>
                        {getNurseryInfo(nurseryDates, date)}
                      </NurseryBox>
                    }
                  />
                </ListItem>
                {index < dates.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        </CampPaper>
      </Box>
    </>
  );
};
