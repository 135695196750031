import React from "react";
import { Badge } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  secondary: {
    backgroundColor: theme.palette.error.main,
  },
}));

export const MemberBadge = ({ ...props }) => {
  const classes = useStyles();

  return <Badge classes={{ colorSecondary: classes.secondary }} {...props} />;
};
