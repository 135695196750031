import React from "react";
import { Avatar } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useAvatarStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  rounded: {
    borderRadius: "4px",
  },
}));

export const MemberAvatar = ({ src }) => {
  const classes = useAvatarStyles();

  return <Avatar variant="rounded" src={src} className={classes.large} />;
};
