import React, { Fragment, useCallback, useEffect, useState } from "react";
import upperFirst from "lodash/upperFirst";
import { MembersTable } from "./member/MembersTable";
import { updateActivityItem } from "../utils/apiFetcher";
import { useMutation, useQueryClient } from "react-query";
import { useTableStyles } from "./table/useTableStyles";
import { TableData } from "./table/TableData";
import { IconButton, Tooltip } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import { useHasMasterRights } from "../utils/useHasMasterRights";
import { useDispatch } from "react-redux";
import { throwError } from "../actions";
import values from "lodash/values";
import { useParams } from "react-router-dom";
import { NurseryIcon } from "./member/NurseryIcon";
import filter from "lodash/filter";
import deburr from "lodash/deburr";

const TooltipButton = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
    "&.Mui-focused": {
      color: "inherit",
    },
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
}))(IconButton);

export const ActivityItem = ({
  type,
  itemLabel,
  itemDetails,
  week,
  currentWeek,
  numberOfWeekDays,
  searchValue,
}) => {
  const { season } = useParams();
  const [members, setMembers] = useState([]);
  const classes = useTableStyles();
  const hasMasterRights = useHasMasterRights();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: updateLocked } = useMutation(updateActivityItem, {
    onMutate: ({ label, locked }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(["membersByTemporaryActivity", type, season]);

      // Snapshot the previous value
      const previousItems = queryClient.getQueryData([
        "membersByTemporaryActivity",
        type,
        season,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["membersByTemporaryActivity", type, season],
        (items) =>
          week
            ? {
                ...items,
                [week]: {
                  ...items[week],
                  [label]: { ...items[week][label], locked: locked },
                },
              }
            : {
                ...items,
                [label]: { ...items[label], locked: locked },
              }
      );

      // Return the snapshotted value
      return () =>
        queryClient.setQueryData(
          ["membersByTemporaryActivity", type, season],
          previousItems
        );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("membersByTemporaryActivity");
    },
    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (error, { locked }, rollback) => {
      error.customMessage = `Impossible ${
        locked ? "de fermer" : "d'ouvrir"
      } les inscriptions...`;
      dispatch(throwError(error));
      rollback();
    },
  });
  const onClick = useCallback(
    () =>
      hasMasterRights &&
      updateLocked({
        id: itemDetails.id,
        label: itemLabel,
        locked: !itemDetails.locked,
        time:
          itemLabel.indexOf("après-midi") !== -1
            ? "pm"
            : itemLabel.indexOf("matin") !== -1
            ? "am"
            : "",
        numberOfDays: numberOfWeekDays,
      }),
    [hasMasterRights, itemDetails, itemLabel, updateLocked, numberOfWeekDays]
  );

  const activityItems = itemLabel.split("|");
  const title = `${itemDetails.locked ? "Ouvrir" : "Fermer"} les inscriptions`;

  const renderAdditionalInfo = useCallback(
    (member) => {
      if (
        member.custom_multiple_garderie &&
        (member.custom_multiple_garderie.indexOf(activityItems[0]) !== -1 ||
          member.custom_multiple_garderie.indexOf(
            activityItems[0].replace(/(matin|après-midi)/, "journée")
          ) !== -1)
      ) {
        return <NurseryIcon />;
      }

      return null;
    },
    [activityItems]
  );

  useEffect(() => {
    if (searchValue) {
      const value = deburr(searchValue.toLowerCase());
      let filteredMembers = values(itemDetails.members);
      filteredMembers = filter(
        filteredMembers,
        (member) =>
          deburr(member.Nom).toLowerCase().indexOf(value) !== -1 ||
          deburr(member["Prénom"]).toLowerCase().indexOf(value) !== -1 ||
          deburr(`${member["Prénom"]} ${member.Nom}`)
            .toLowerCase()
            .indexOf(value) !== -1 ||
          deburr(member.parent1_last_name).toLowerCase().indexOf(value) !== -1
      );

      setMembers(filteredMembers);
    } else {
      setMembers(values(itemDetails.members));
    }
  }, [itemDetails, searchValue]);

  return (
    <Fragment key={itemLabel}>
      <TableData className={classes.header}>
        <TableData>{upperFirst(activityItems[0])}</TableData>
        {activityItems.length > 1 && (
          <TableData>{upperFirst(activityItems[1])}</TableData>
        )}
        <TableData className={classes.tooltip}>
          {itemDetails.id && (!week || week >= currentWeek) && (
            <Tooltip title={title}>
              <span>
                <TooltipButton
                  aria-label={title}
                  color="inherit"
                  onClick={onClick}
                  disabled={!hasMasterRights}
                >
                  {itemDetails.locked ? (
                    <LockRoundedIcon />
                  ) : (
                    <LockOpenRoundedIcon />
                  )}
                </TooltipButton>
              </span>
            </Tooltip>
          )}
        </TableData>
      </TableData>
      <MembersTable
        size="small"
        members={members}
        noBadge
        renderAdditionalInfo={renderAdditionalInfo}
      />
    </Fragment>
  );
};
