import React from "react";
import { Skeleton } from "@mui/material";
import { Name } from "../../Name";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/styles";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";

const Detail = ({ children }) => (
  <Typography variant="body2" color="textSecondary">
    {children}
  </Typography>
);

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 1, 0, 0),
}));

export const SingleContactDetail = ({
  displayName,
  lastName,
  firstName,
  address,
  city,
  postCode,
  email,
  phone,
  isLoading,
}) => {
  const details = isLoading ? (
    <>
      <Skeleton width={190} />
      <Skeleton width={210} />
      <Skeleton width={100} />
    </>
  ) : (
    <>
      <Detail>{address}</Detail>
      <Detail>
        {postCode} {city?.toUpperCase()}
      </Detail>
      <Detail>{phone?.replaceAll(" ", "")}</Detail>
      <Detail>{email?.toLowerCase()}</Detail>
      {phone && (
        <ActionButton
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<PhoneRoundedIcon />}
          href={`tel:${phone?.replaceAll(" ", "")}`}
        >
          Appeler
        </ActionButton>
      )}
      {email && (
        <ActionButton
          variant="outlined"
          color="secondary"
          size="small"
          startIcon={<MailRoundedIcon />}
          href={`mailto:${email?.toLowerCase()}`}
        >
          Envoyer un mail
        </ActionButton>
      )}
    </>
  );

  if (displayName) {
    return (
      <>
        <Name
          firstName={firstName}
          lastName={lastName}
          variant="body2"
          color="textSecondary"
        />
        {details}
      </>
    );
  }

  return details;
};
