import React from "react";
import { Name } from "../Name";

export const MemberName = ({ member, ...rest }) => {
  if (!member) {
    return null;
  }

  return <Name firstName={member["Prénom"]} lastName={member.Nom} {...rest} />;
};
