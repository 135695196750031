import React from "react";
import { Chip, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";

const useStyles = makeStyles((theme) => ({
  new: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
}));

export const CompetitorChip = ({ permit }) => {
  const classes = useStyles();

  if (permit !== "Compétition") {
    return null;
  }

  return (
    <Grid item xs="auto">
      <Chip
        size="small"
        label="compétiteur"
        color="primary"
        icon={<EmojiEventsRoundedIcon />}
        classes={{
          colorPrimary: classes.new,
        }}
      />
    </Grid>
  );
};
