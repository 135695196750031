import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FaceIcon from "@mui/icons-material/FaceRounded";
import HomeIcon from "@mui/icons-material/HomeRounded";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbolRounded";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheckRounded";
import { getHasUndeliveredDocument } from "../../utils/member";
import { MemberBadge } from "./MemberBadge";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const MemberBottomBar = ({
  member,
  activeTab,
  isAnnual,
  isNursery,
  setActiveTab,
}) => {
  const classes = useStyles();

  return (
    <BottomNavigation
      value={activeTab}
      onChange={(event, newValue) => {
        setActiveTab(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label={isAnnual && !isNursery ? "Adhérent" : "Participant"}
        icon={<FaceIcon />}
      />
      <BottomNavigationAction label="Coordonnées" icon={<HomeIcon />} />
      <BottomNavigationAction label="Finances" icon={<EuroSymbolIcon />} />
      {isAnnual && (
        <BottomNavigationAction
          label="Check list"
          icon={
            <MemberBadge
              color={
                member?.accessories === "on-hold" ? "primary" : "secondary"
              }
              variant="dot"
              invisible={
                !getHasUndeliveredDocument(member, isNursery) &&
                member?.accessories !== "on-hold"
              }
            >
              <PlaylistAddCheckIcon />
            </MemberBadge>
          }
        />
      )}
    </BottomNavigation>
  );
};
