import React, { useReducer } from "react";
import find from "lodash/find";
import { PaymentCard } from "./PaymentCard";
import { DepositPaymentCard } from "./DepositPaymentCard";
import map from "lodash/map";
import { sortBy } from "lodash";

const initialState = {
  deposit: {
    shouldBePaid: false,
    isPaid: false,
  },
  second_payment: {
    shouldBePaid: false,
    isPaid: false,
  },
};

const migrationDictionnary = {
  deposit: "deposit",
  second_payment: "second_payment",
  partial_payment: "second_payment",
};

function subOrderReducer(state, action) {
  switch (action.type) {
    case "deposit":
      let shouldSecondPaymentBePaid = state.second_payment.shouldBePaid;
      if (state.deposit.shouldBePaid === action.isPaid) {
        shouldSecondPaymentBePaid = action.isPaid;
      }

      return {
        second_payment: { shouldBePaid: shouldSecondPaymentBePaid },
        deposit: { shouldBePaid: !action.isPaid },
      };
    case "second_payment":
      let shouldBePaid = !action.isPaid;
      if (state.deposit.shouldBePaid) {
        shouldBePaid = false;
      }

      return { ...state, second_payment: { shouldBePaid } };
    default:
      throw new Error();
  }
}

export const DepositPayment = ({ order }) => {
  const [state, setIsPaid] = useReducer(subOrderReducer, initialState);
  const schedule = find(
    order.meta_data,
    (meta) => meta.key === "_wc_deposits_payment_schedule"
  );

  if (!schedule) {
    return <PaymentCard />;
  }

  return map(
    sortBy(schedule.value, (value) => value.id),
    (value) => (
      <DepositPaymentCard
        key={value.id}
        id={value.id}
        parentOrder={order}
        paidStatus={value.type === "deposit" ? "partially-paid" : "processing"}
        type={migrationDictionnary[value.type]}
        shouldBePaid={state[migrationDictionnary[value.type]].shouldBePaid}
        setIsPaid={setIsPaid}
      />
    )
  );
};
