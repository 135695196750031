import React, { Suspense, useEffect } from "react";
import { Loader } from "../Loader";
import { CampFinancialReport } from "./CampFinancialReport";
import { Chart as ChartJS } from "chart.js";

function resizeHandler() {
  for (let id in ChartJS.instances) {
    ChartJS.instances[id].resize();
  }
}

export const FinancialReport = () => {
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <CampFinancialReport />
      </Suspense>
    </>
  );
};
