import React from "react";
import { useQuery } from "react-query";
import { fetchMembers } from "../../utils/apiFetcher";
import { MembersTable } from "./MembersTable";
import filter from "lodash/filter";
import deburr from "lodash/deburr";
import { shouldRequestCard } from "../../utils/member";
import { useParams } from "react-router-dom";

export const MemberSearchResult = ({
  searchValue,
  notPaidFilter,
  preRegistredFilter,
  inValidationFilter,
  newMemberFilter,
  competitorFilter,
  regionalCardFilter,
  governmentalCardFilter,
  accessoriesFilter,
  cardFilter,
}) => {
  const { season } = useParams();
  const { data: members } = useQuery(["members", season], () =>
    fetchMembers(season)
  );
  let result = members;

  if (searchValue) {
    const value = deburr(searchValue.toLowerCase());
    result = filter(
      result,
      (member) =>
        deburr(member.Nom).toLowerCase().indexOf(value) !== -1 ||
        deburr(member["Prénom"]).toLowerCase().indexOf(value) !== -1 ||
        deburr(`${member["Prénom"]} ${member.Nom}`)
          .toLowerCase()
          .indexOf(value) !== -1 ||
        deburr(member.parent1_last_name).toLowerCase().indexOf(value) !== -1 ||
        deburr(member["parent2-Nom"]).toLowerCase().indexOf(value) !== -1
    );
  }

  if (notPaidFilter) {
    result = filter(result, (member) => member.payment_status === "waiting");
  }

  if (preRegistredFilter) {
    result = filter(
      result,
      (member) => member.payment_status === "partiallyPaid"
    );
  }

  if (inValidationFilter) {
    result = filter(
      result,
      (member) => member.payment_status === "inValidation"
    );
  }

  if (newMemberFilter) {
    result = filter(result, (member) => member.new);
  }

  if (competitorFilter) {
    result = filter(result, (member) => member.Licence === "Compétition");
  }

  if (regionalCardFilter) {
    result = filter(result, (member) => member["N° De La Carte Pass'région"]);
  }

  if (governmentalCardFilter) {
    result = filter(result, (member) => member["Code Du Pass'sport"]);
  }

  if (accessoriesFilter) {
    result = filter(result, (member) => member.accessories);
  }

  if (cardFilter) {
    result = filter(
      result,
      (member) =>
        shouldRequestCard(member) && !member._federation_card_requested
    );
  }

  return <MembersTable members={result} noCount isAnnual sort />;
};
