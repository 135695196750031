import React, { useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import EuroIcon from "@mui/icons-material/EuroRounded";
import { getPayDate, getTotal } from "../../../utils/order";
import { useHasMasterRights } from "../../../utils/useHasMasterRights";
import map from "lodash/map";
import set from "lodash/set";
import { useMutation, useQueryClient } from "react-query";
import { setOrderAccessory } from "../../../utils/apiFetcher";
import { Skeleton } from "@mui/material";
import { usePayment } from "../../../utils/usePayment";
import { throwError } from "../../../actions";
import { useDispatch } from "react-redux";
import { PaymentIcon } from "../finances/PaymentIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  notPaid: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
  },
  paid: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(0.5),
  },
}));

export const AccessoryCard = ({ member, order }) => {
  const updateQuery = useRef();
  const classes = useStyles();
  const date = getPayDate(order);
  const hasMasterRights = useHasMasterRights();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  let displayPaidButton =
    order &&
    order.standalone &&
    order.status !== "paid" &&
    order.status !== "done";

  if (!hasMasterRights && order?.status === "inValidation") {
    displayPaidButton = false;
  }

  const builtPaidStatus = hasMasterRights ? "processing" : "on-validation";

  const handlePayment = usePayment(
    { order, paidStatus: builtPaidStatus },
    {
      set: (value) =>
        queryClient.setQueryData(["member", member.registration_id], value),
      cancel: () =>
        queryClient.cancelQueries(["member", member.registration_id]),
      modify: (oldMember) => {
        const status = hasMasterRights ? "paid" : "inValidation";
        set(oldMember, ["accessories", order.id, "status"], status);

        return oldMember;
      },
      getPrevious: () =>
        queryClient.getQueryData(["member", member.registration_id]),
      invalidate: () =>
        setTimeout(() => {
          queryClient.invalidateQueries("member", member.registration_id);
          queryClient.invalidateQueries("members");
        }, 5000),
    },
    [queryClient, member, order]
  );

  const { mutate: setOrderAccessoryMutation } = useMutation(setOrderAccessory, {
    onMutate: ({ item, handDelivered }) => {
      if (updateQuery.current && updateQuery.current.cancel) {
        updateQuery.current.cancel();
      }

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(["member", member.registration_id]);
      queryClient.cancelQueries(["members"]);

      // Snapshot the previous value
      const previousMember = queryClient.getQueryData([
        "member",
        member.registration_id,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["member", member.registration_id],
        (oldMember) => {
          set(oldMember, ["accessories", order.id, "items", item.id], {
            ...item,
            handDelivered,
          });

          return oldMember;
        }
      );

      // Return the snapshotted value
      return () =>
        queryClient.setQueryData(
          ["member", member.registration_id],
          previousMember
        );
    },
    onSuccess: () => {
      queryClient.invalidateQueries("members");
    },
    onError: (error, variables, rollback) => {
      error.customMessage = "Impossible de mettre à jour l'adhérent...";
      dispatch(throwError(error));
      rollback();
    },
  });

  const handleToggle = (item) => () => {
    setOrderAccessoryMutation({
      id: order.id,
      item,
      handDelivered: !order.items[item.id].handDelivered,
    });
  };

  return (
    <Grid item xs={12} md={6}>
      <Card className={classes.root}>
        <CardContent>
          {!order ? (
            <>
              <Skeleton width={160} height={30} />
              <Skeleton width={140} />
            </>
          ) : (
            <>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {map(order.items, (item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          onChange={handleToggle(item)}
                          name={item.label}
                          checked={item.handDelivered || false}
                          disabled={!hasMasterRights && item.handDelivered}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {item.label}
                          {item.variation ? ` -  ${item.variation}` : ""}
                        </Typography>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
              {order.standalone ? (
                <>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">{getTotal(order)}€</Typography>
                    <PaymentIcon status={order?.status} />
                  </Box>
                  {order.status === "paid" && (
                    <>
                      {date ? (
                        <Typography color="textSecondary" variant="body2">
                          payé le {date}
                        </Typography>
                      ) : null}
                      {order?.payment_method_title && (
                        <Typography color="textSecondary" variant="body2">
                          par {order.payment_method_title.toLowerCase()}
                        </Typography>
                      )}
                    </>
                  )}
                  {order.status !== "paid" && order.status !== "done" && (
                    <Typography color="textSecondary" variant="body2">
                      en attente
                      {order.status === "inValidation" && " de validation"}
                    </Typography>
                  )}
                </>
              ) : (
                <Box display="flex" alignItems="center">
                  <Typography color="textSecondary" variant="body2">
                    lié{order.items.length > 1 ? "s" : ""} à une autre commande
                  </Typography>
                  <PaymentIcon status={order?.status} />
                </Box>
              )}
            </>
          )}
        </CardContent>
        {displayPaidButton && (
          <CardActions>
            <Button
              size="small"
              variant="contained"
              startIcon={<EuroIcon />}
              color="secondary"
              onClick={handlePayment}
            >
              Paiement reçu
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};
