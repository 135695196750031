import React, { Fragment } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { useQuery } from "react-query";
import { CircularProgress, Box, ListItemIcon, Divider } from "@mui/material";
import { styled } from "@mui/styles";
import upperFirst from "lodash/upperFirst";
import { fetchCourses } from "../../../utils/apiFetcher";
import { formatCourseTime, getActivityIcon } from "../../../utils/course";
import { useParams } from "react-router-dom";

const Title = styled(DialogTitle)(({ theme }) => ({
  top: "0px",
  position: "sticky",
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.appBar,
}));

export const CourseSelectionModal = ({ member, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (nextCourse) => {
    onClose(nextCourse);
  };

  const productId = member._product_id;
  const { season } = useParams();
  const { data: coursesData } = useQuery(
    ["coursesByProduct", season, productId],
    () => fetchCourses(season, productId),
    { enabled: open, suspense: false, staleTime: Infinity }
  );

  let lastCourseDay;

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="course-selection-title"
      open={open}
    >
      <Title id="course-selection-title">Sélectionner un cours</Title>
      {!coursesData && (
        <Box pt={4} pb={6} mx="auto">
          <CircularProgress color="inherit" />
        </Box>
      )}
      {coursesData && (
        <List dense>
          {coursesData.map((course) => {
            if (!lastCourseDay) {
              lastCourseDay = course.day;
            }

            const item = (
              <Fragment key={course.id}>
                {lastCourseDay !== course.day && <Divider />}
                <ListItem button onClick={() => handleListItemClick(course)}>
                  <ListItemIcon>
                    {getActivityIcon(course.activity)}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${upperFirst(course.day)} ${
                      course.allout === "0" ? formatCourseTime(course) : ""
                    }`}
                    secondary={`${upperFirst(course.activity)}${
                      course.allout === "0" ? ` - ${course.label}` : ""
                    }`}
                  />
                </ListItem>
              </Fragment>
            );

            lastCourseDay = course.day;

            return item;
          })}
        </List>
      )}
    </Dialog>
  );
};
