import React, { forwardRef, useCallback, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  ListItemIcon,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { MemberAvatar } from "../MemberAvatar";
import { PaymentChip } from "../PaymentChip";
import { NewMemberChip } from "../NewMemberChip";
import { CompetitorChip } from "../CompetitorChip";
import { MemberCourseList } from "./MemberCourseList";
import get from "lodash/get";
import { useHasMasterRights } from "../../../utils/useHasMasterRights";
import { useChecked } from "../../../utils/useChecked";
import { setMemberCard } from "../../../utils/apiFetcher";
import {
  formatNumberOfChildren,
  shouldRequestCard,
} from "../../../utils/member";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MemberCampList } from "./MemberCampList";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  specialCard: {
    display: "block",
  },
  icon: {
    minWidth: "auto",
  },
}));

export const MemberDetails = forwardRef(
  ({ member, memberData, isAnnual, setLocalChange, ...rest }, ref) => {
    const classes = useStyles();
    const hasTopManagerRights = useHasMasterRights(true);
    const callPermitFunction = useCallback(
      (cardRequested) =>
        setMemberCard({
          id: member.registration_id,
          requested: cardRequested,
          name: "permit",
        }),
      [member]
    );
    const callRegionalFunction = useCallback(
      (cardRequested) =>
        setMemberCard({
          id: member.registration_id,
          requested: cardRequested,
          name: "regional",
        }),
      [member]
    );
    const callGovernmentalFunction = useCallback(
      (cardRequested) =>
        setMemberCard({
          id: member.registration_id,
          requested: cardRequested,
          name: "governmental",
        }),
      [member]
    );
    const [permitCardChecked, handlePermitCardToggle] = useChecked(
      member._federation_card_requested,
      callPermitFunction
    );
    const [regionalCardChecked, handleRegionalCardToggle] = useChecked(
      member._regional_card_payment_requested,
      callRegionalFunction
    );
    const [governmentalCardChecked, handleGovernmentalCardToggle] = useChecked(
      member._governmental_card_payment_requested,
      callGovernmentalFunction
    );
    const [tooltipText, setTooltipText] = useState("Copier le code");
    const setCopied = useCallback(
      () => setTooltipText("Copié !"),
      [setTooltipText]
    );
    const resetCopied = useCallback(
      () => setTimeout(() => setTooltipText("Copier le code"), 200),
      [setTooltipText]
    );

    let secondaryInfo = member.Sexe ? <span>Sexe : {member.Sexe}</span> : null;
    if (member["Date De Naissance"]) {
      secondaryInfo = (
        <>
          {secondaryInfo}
          <br />
          <span>N° d'adhérent : {member.registration_id}</span>
        </>
      );
    }
    if (member["Numéro D'agrément"]) {
      secondaryInfo = (
        <>
          {secondaryInfo}
          <span className={classes.specialCard}>
            Numéro d'agrément : {member["Numéro D'agrément"]}
          </span>
        </>
      );
    }

    return (
      <div ref={ref} {...rest}>
        <div className={classes.chipContainer}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <PaymentChip status={member.payment_status} />
            <NewMemberChip isNew={member.new} />
            <CompetitorChip permit={member.Licence} />
          </Grid>
        </div>
        <List>
          <ListItem dense>
            <MemberAvatar src={get(member, ["files", "photo"])} />
          </ListItem>
          <ListItem dense>
            <ListItemText
              primary={
                member["Date De Naissance"] ||
                (member["Nombre D'enfants"] &&
                  formatNumberOfChildren(member["Nombre D'enfants"]))
              }
              secondary={secondaryInfo}
            />
          </ListItem>
          {shouldRequestCard(member) && (
            <>
              <ListItem dense>
                <ListItemIcon className={classes.icon}>
                  <Checkbox
                    checked={permitCardChecked}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    onClick={handlePermitCardToggle}
                    disabled={!hasTopManagerRights}
                    inputProps={{ "aria-labelledby": "federation_card" }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={"federation_card"}
                  primary={
                    <>
                      Licence <em>{member["Licence"].toLowerCase()}</em>{" "}
                      demandée à la FFGym
                    </>
                  }
                />
              </ListItem>
            </>
          )}
          {member["N° De La Carte Pass'région"] && (
            <>
              <ListItem
                dense
                secondaryAction={
                  <CopyToClipboard
                    text={member["N° De La Carte Pass'région"]}
                    onCopy={setCopied}
                  >
                    <Tooltip title={tooltipText} onClose={resetCopied}>
                      <IconButton edge="end" aria-label="delete">
                        <ContentCopyRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                }
              >
                <ListItemIcon className={classes.icon}>
                  <Checkbox
                    checked={regionalCardChecked}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    onClick={handleRegionalCardToggle}
                    disabled={!hasTopManagerRights}
                    inputProps={{ "aria-labelledby": "regional_card" }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={"regional_card"}
                  primary={`N° Pass'Région : ${
                    member["N° De La Carte Pass'région"]
                  } ・ Code : ${
                    member["Code De La Carte Pass'région"] || "non renseigné"
                  }`}
                />
              </ListItem>
            </>
          )}
          {member["Code Du Pass'sport"] && (
            <>
              <ListItem
                dense
                disabled={!hasTopManagerRights}
                secondaryAction={
                  <CopyToClipboard
                    text={member["Code Du Pass'sport"].toUpperCase()}
                    onCopy={setCopied}
                  >
                    <Tooltip title={tooltipText} onClose={resetCopied}>
                      <IconButton edge="end" aria-label="delete">
                        <ContentCopyRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                }
              >
                <ListItemIcon className={classes.icon}>
                  <Checkbox
                    checked={governmentalCardChecked}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    onClick={handleGovernmentalCardToggle}
                    disabled={!hasTopManagerRights}
                    inputProps={{ "aria-labelledby": "governmental_card" }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={"governmental_card"}
                  primary={`Code Pass'Sport : ${member[
                    "Code Du Pass'sport"
                  ].toUpperCase()}`}
                />
              </ListItem>
            </>
          )}
        </List>
        {isAnnual && (
          <MemberCourseList
            member={member}
            memberData={memberData}
            setLocalChange={setLocalChange}
          />
        )}
        {member["custom_multiple_dates"] && (
          <MemberCampList
            campName={member["registration_name"]}
            dates={member["custom_multiple_dates"]}
            nurseryDates={member["custom_multiple_garderie"]}
          />
        )}
      </div>
    );
  }
);
