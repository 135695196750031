import React, { useEffect, useCallback } from "react";
import { useQuery } from "react-query";
import { fetchOrder } from "../../../utils/apiFetcher";
import { PaymentCard } from "./PaymentCard";

export const DepositPaymentCard = ({
  id,
  type,
  shouldBePaid,
  setIsPaid,
  parentOrder,
  paidStatus,
}) => {
  const { data } = useQuery(["order", id], () => fetchOrder(id), {
    suspense: false,
    staleTime: 60000,
  });
  const dispatchPaymentStatus = useCallback(
    (isPaid) => setIsPaid({ type: type, isPaid }),
    [setIsPaid, type]
  );

  const orderStatus = data && data.status;

  useEffect(() => {
    if (orderStatus) {
      if (orderStatus === "completed") {
        dispatchPaymentStatus(true);
      } else {
        dispatchPaymentStatus(false);
      }
    }
  }, [dispatchPaymentStatus, orderStatus]);

  if (!data) {
    return <PaymentCard />;
  }

  const label = type === "deposit" ? "Pré-inscription" : "Second paiement";

  return (
    <PaymentCard
      order={data}
      label={label}
      shouldBePaid={shouldBePaid}
      canSendMail={type === "second_payment" && shouldBePaid}
      parentOrder={parentOrder}
      paidStatus={paidStatus}
    />
  );
};
