import { useCallback } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { throwError } from "../actions";
import { updateOrder } from "./apiFetcher";
import { getTotal } from "./order";

export const usePayment = (
  { order, paidStatus, parentOrder },
  { set, cancel, modify, getPrevious, invalidate },
  dependencies = []
) => {
  const dispatch = useDispatch();
  const { mutate: markAsPaid } = useMutation(updateOrder, {
    onMutate: () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      cancel();

      // Snapshot the previous value
      const previous = getPrevious();

      // Optimistically update to the new value
      set(modify);

      // Return the snapshotted value
      return () => set(previous);
    },

    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (error, newOrder, rollback) => {
      error.customMessage = "Impossible de mettre à jour le paiement...";
      dispatch(throwError(error));
      rollback();
    },
  });

  return useCallback(async () => {
    if (
      window.confirm(
        `Êtes-vous sûr d'avoir reçu un paiement de ${getTotal(
          order
        )}€ pour cette commande ?`
      )
    ) {
      if (parentOrder) {
        await markAsPaid({
          id: order.id,
          status: paidStatus === "on-validation" ? paidStatus : "completed",
        });
        await markAsPaid({ id: parentOrder.id, status: paidStatus });
        invalidate(parentOrder.id);
      } else {
        await markAsPaid({ id: order.id, status: paidStatus });
      }
      invalidate(order.id);
    }
  }, [invalidate, markAsPaid, order, paidStatus, parentOrder]);
};
