import React, { useState, useCallback, useEffect, useRef } from "react";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Drawer } from "./Drawer";
import { Link } from "react-router-dom";
import {
  InputBase,
  Collapse,
  Grid,
  FormControlLabel,
  Switch,
  Badge,
  InputAdornment,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { HideOnScroll } from "./components/HideOnScroll";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover, &:focus": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    paddingLeft: theme.spacing(1),
    width: "auto",
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: "inherit",
  },
  inputRoot: {
    color: "inherit",
    transition: theme.transitions.create("width"),
    width: "14ch",
    "&:focus-within": {
      width: "16ch",
    },
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus-within": {
        width: "28ch",
      },
    },
  },
  filters: {
    padding: theme.spacing(0, 4, 1),
  },
  filterLabel: {
    fontSize: theme.typography.pxToRem(13),
  },
  clearButton: {
    padding: 0,
  },
}));

export const Header = ({
  searchEnabled,
  setSearchValue,
  moreMenu,
  title,
  filters,
  children,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [displaySearchFilters, setDisplaySearchFilters] = useState(false);
  const filterInitialState = {};
  filters.forEach((filter) => {
    filterInitialState[filters.name] = false;
  });

  const [filterState, setFilterState] = useState(filterInitialState);
  const input = useRef();

  useEffect(() => {
    if (!searchEnabled) {
      setFilterState({});
      setDisplaySearchFilters(false);
      setSearchValue("");
      setInputValue("");
    }
  }, [
    searchEnabled,
    setSearchValue,
    setInputValue,
    setFilterState,
    setDisplaySearchFilters,
  ]);

  const handleFilterChange = useCallback(
    (event) => {
      setFilterState({
        ...filterState,
        [event.target.name]: event.target.checked,
      });
      const handle = find(filters, { name: event.target.name })?.handle;
      if (handle) {
        handle(event.target.checked);
      }
    },
    [filterState, filters]
  );

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClear = useCallback(() => {
    input.current.focus();
    setInputValue("");
    setSearchValue("");
  }, [setSearchValue]);

  const onSearch = useCallback(
    (event) => {
      setSearchValue(event.target.value);
      setInputValue(event.target.value);
    },
    [setSearchValue]
  );

  let moreMenuComponent;
  let searchComponent;
  let filterComponent;

  if (searchEnabled) {
    searchComponent = (
      <>
        <div className={classes.search}>
          <InputBase
            inputRef={input}
            placeholder="Chercher..."
            className={classes.inputRoot}
            onChange={onSearch}
            inputProps={{ "aria-label": "recherche" }}
            value={inputValue}
            endAdornment={
              <InputAdornment position="end" className={classes.searchIcon}>
                {inputValue ? (
                  <IconButton
                    className={classes.clearButton}
                    color="inherit"
                    onClick={handleClear}
                    size="large"
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                ) : (
                  <SearchRoundedIcon />
                )}
              </InputAdornment>
            }
          />
        </div>
        {!isEmpty(filters) && (
          <>
            <IconButton
              aria-label="filtres de recherche"
              edge="end"
              color="inherit"
              onClick={() => setDisplaySearchFilters(!displaySearchFilters)}
              size="large"
            >
              <Badge
                color="secondary"
                variant="dot"
                invisible={!find(filterState, (value) => value)}
              >
                <TuneRoundedIcon />
              </Badge>
            </IconButton>
          </>
        )}
      </>
    );

    filterComponent = !isEmpty(filters) && (
      <Collapse in={displaySearchFilters}>
        <Grid container spacing={1} className={classes.filters}>
          {filters.map((filter) => (
            <Grid item xs={6} key={filter.name}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={filterState[filter.name] || false}
                    onChange={handleFilterChange}
                    name={filter.name}
                    color="secondary"
                  />
                }
                label={filter.label}
                classes={{
                  label: classes.filterLabel,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    );
  }

  if (moreMenu) {
    moreMenuComponent = (
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {moreMenu.map(({ to, label, handleClick }) => (
          <MenuItem
            key={label}
            onClick={() => {
              handleClose();
              handleClick && handleClick();
            }}
            component={Link}
            to={to}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    );
  }

  return (
    <HideOnScroll>
      <AppBar>
        <Toolbar>
          <Drawer />
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {searchComponent}
          {moreMenuComponent && (
            <>
              <IconButton
                aria-label="afficher plus d'action"
                edge="end"
                color="inherit"
                onClick={handleClick}
                aria-controls="more-menu"
                aria-haspopup="true"
                size="large"
              >
                <MoreIcon />
              </IconButton>
              {moreMenuComponent}
            </>
          )}
          {children}
        </Toolbar>
        {filterComponent}
      </AppBar>
    </HideOnScroll>
  );
};

Header.defaultProps = {
  filters: [],
};
