import React, { useState } from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { Box, Button, Container, LinearProgress, Toolbar } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { useIsMutating, useQuery } from "react-query";
import { Header } from "./Header";

import { fetchCurrentUser } from "./utils/apiFetcher";
import { ReactQueryDevtools } from "react-query-devtools";
import { Routes } from "./Routes";
import { ErrorSnackbar } from "./components/ErrorSnackbar";
import { Loader } from "./components/Loader";
import { useDispatch } from "react-redux";
import { throwError } from "./actions";

const theme = createTheme({
  palette: {
    primary: {
      main: "#7bae58",
      contrastText: "#fff",
    },
    secondary: {
      main: "#1d273a",
    },
    background: {
      red: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      blue: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
      purple: "linear-gradient(45deg, #cc5adc 30%, #ea80ff 90%)",
      yellow: "linear-gradient(45deg, #e1da19 30%, #eff788 90%)",
    },
    progress: {
      red: "#b32d00",
      blue: "#085391",
    },
  },
  typography: {
    subtitle1: {
      fontWeight: 600,
    },
  },
});

const App = () => {
  const [moreMenu, setMoreMenu] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [searchEnabled, enableSearch] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [filters, setFilters] = useState();
  const [isHeaderVisible, setHeaderVisible] = useState(true);
  const isMutating = useIsMutating();
  const dispatch = useDispatch();

  const homepageUrl = process.env.REACT_APP_HOMEPAGE_URL;
  const { isLoading, isError } = useQuery(["currentUser"], fetchCurrentUser, {
    onError: (error) => dispatch(throwError(error)),
    suspense: false,
    staleTime: "Infinity",
    retry: 2,
  });

  return (
    <Router basename={homepageUrl}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {!!isMutating && (
            <Box position="fixed" width="100%" zIndex="drawer">
              <LinearProgress />
            </Box>
          )}
          <div className="App">
            {isError && (
              <Container
                sx={{
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => document.location.reload()}
                >
                  Recharger l'application
                </Button>
              </Container>
            )}
            {!isError &&
              (isLoading ? (
                <Loader />
              ) : (
                <>
                  {isHeaderVisible && (
                    <>
                      <Header
                        title={pageTitle}
                        moreMenu={moreMenu}
                        searchEnabled={searchEnabled}
                        setSearchValue={setSearchValue}
                        filters={filters}
                      />
                      <Toolbar />
                    </>
                  )}
                  <Routes
                    {...{
                      setPageTitle,
                      setMoreMenu,
                      enableSearch,
                      setFilters,
                      searchValue,
                      setHeaderVisible,
                    }}
                  />
                </>
              ))}

            <ErrorSnackbar />
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
      {/* <ReactQueryDevtools initialIsOpen /> */}
    </Router>
  );
};

export default App;
