import React from "react";
import { Typography } from "@mui/material";
import find from "lodash/find";
import filter from "lodash/filter";
import get from "lodash/get";
import isString from "lodash/isString";

const ALLOUT_NAME = "Pack Forme";

export const Frequency = ({ metadata }) => {
  const isAllout = !!find(
    metadata,
    (meta) => isString(meta.value) && meta.value.indexOf(ALLOUT_NAME) !== -1
  );

  const frequencyValue = filter(metadata, { key: "_course_id" }).length;
  let frequency = `${frequencyValue} fois/semaine`;

  if (isAllout) {
    frequency = ALLOUT_NAME;
  } else if (!frequencyValue) {
    return null;
  }

  return (
    <Typography color="textSecondary" variant="body2">
      {frequency}{" "}
      {get(
        find(metadata, (meta) => meta.key.indexOf("Licence") !== -1),
        "value",
        ""
      ).toLowerCase()}
    </Typography>
  );
};
