import React from "react";
import { useQuery } from "react-query";
import {
  fetchGeneralStats,
  fetchActivitiesStats,
  fetchMembers,
} from "../../utils/apiFetcher";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { StatPaper } from "./StatPaper";
import PeopleIcon from "@mui/icons-material/PeopleRounded";
import MoneyOffRoundedIcon from "@mui/icons-material/MoneyOffRounded";
import LocalAtmIcon from "@mui/icons-material/LocalAtmRounded";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import InputRoundedIcon from "@mui/icons-material/InputRounded";
import get from "lodash/get";
import filter from "lodash/filter";
import size from "lodash/size";
import { getActivityIcon } from "../../utils/course";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(1),
  },
}));

const membershipTarget = 420;

const activities = [
  {
    label: "Bambins à ressorts",
    key: "bambins_a_ressorts",
  },
  {
    label: "Trampoline",
    key: "trampoline",
  },
  {
    label: "Fitness",
    key: "fitness",
  },
  {
    label: "Gymnastique",
    key: "gymnastique",
  },
  {
    label: "École acrobatique",
    key: "ecole_acrobatique",
  },
];

export const GeneralDashboard = () => {
  const classes = useStyles();
  const { season } = useParams();
  const { data: generalStats } = useQuery(
    ["generalStats", season],
    () => fetchGeneralStats(season),
    {
      suspense: false,
    }
  );

  const { data: activitiesStats } = useQuery(
    ["activitiesStats", season],
    () => fetchActivitiesStats(season),
    {
      suspense: false,
    }
  );

  const { data: members } = useQuery(
    ["members", season],
    () => fetchMembers(season),
    {
      suspense: false,
    }
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4}>
          <StatPaper
            background="red"
            label="Adhérents"
            value={generalStats?.memberCount}
            icon={<PeopleIcon />}
            progressValue={
              generalStats?.memberCount
                ? (generalStats.memberCount / membershipTarget) * 100
                : null
            }
            progressLabel="objectif"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <StatPaper
            background="blue"
            label="Non payées"
            value={generalStats?.notPaidCount}
            icon={<MoneyOffRoundedIcon />}
            progressValue={
              generalStats?.memberCount
                ? (generalStats.memberCount /
                    (generalStats.memberCount +
                      generalStats.partiallyPaidCount +
                      generalStats.inValidation +
                      generalStats.notPaidCount)) *
                  100
                : null
            }
            progressLabel={generalStats?.memberCount ? `payées` : null}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <StatPaper
            background="blue"
            label="Paiement en validation"
            value={generalStats?.inValidation}
            icon={<InputRoundedIcon />}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <StatPaper
            background="blue"
            label="Pré-inscriptions"
            value={generalStats?.partiallyPaidCount}
            icon={<LocalAtmIcon />}
          />
        </Grid>
        {activities.map((activity) => {
          return (
            <Grid item xs={6} sm={4} key={activity.key}>
              <StatPaper
                background="purple"
                label={activity.label}
                value={get(activitiesStats, activity.key)}
                icon={getActivityIcon(activity.label)}
              />
            </Grid>
          );
        })}
        <Grid item xs={6} sm={4}>
          <StatPaper
            background="yellow"
            label="Nouveaux adhérents"
            value={
              members &&
              `${Math.round(
                (filter(
                  members,
                  (member) =>
                    member.new &&
                    (member.payment_status === "paid" ||
                      member.payment_status === "done")
                ).length /
                  size(members)) *
                  100
              )}%`
            }
            icon={<FiberNewRoundedIcon />}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <StatPaper
            background="yellow"
            label="Compétiteurs"
            value={
              members &&
              filter(
                members,
                (member) =>
                  member.Licence === "Compétition" &&
                  (member.payment_status === "paid" ||
                    member.payment_status === "done")
              ).length
            }
            icon={<EmojiEventsRoundedIcon />}
          />
        </Grid>
      </Grid>
    </div>
  );
};
