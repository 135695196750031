import React from "react";
import { Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoneyOffRoundedIcon from "@mui/icons-material/MoneyOffRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import InputRoundedIcon from "@mui/icons-material/InputRounded";

const paramsByStatus = {
  waiting: {
    label: "non payé",
    icon: MoneyOffRoundedIcon,
    color: "error",
  },
  partiallyPaid: {
    label: "pré-inscrit",
    icon: LocalAtmRoundedIcon,
    color: "action",
  },
  inValidation: {
    label: "paiement en validation",
    icon: InputRoundedIcon,
    color: "action",
  },
  done: {
    label: "adhésion expirée",
    icon: HighlightOffRoundedIcon,
    color: "error",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  waiting: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
}));

export const PaymentIcon = ({ status }) => {
  const classes = useStyles();

  if (!status || status === "paid") {
    return null;
  }

  const Component = paramsByStatus[status].icon;

  return (
    <Tooltip title={paramsByStatus[status].label} arrow placement="left">
      <Component
        color={paramsByStatus[status].color}
        classes={{
          root: classes.root,
          colorAction: classes.warning,
          colorError: classes.waiting,
        }}
      />
    </Tooltip>
  );
};
