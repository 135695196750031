import React, { useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../components/Loader";
import { ActivitiesTable } from "../components/table/ActivitiesTable";

export const TemporaryActivities = ({ setPageTitle }) => {
  let { activity } = useParams();

  useEffect(() => {
    setPageTitle("Activités ponctuelles");
  }, [activity, setPageTitle]);

  return (
    <Suspense fallback={<Loader />}>
      <ActivitiesTable type="others" />
    </Suspense>
  );
};
