import React from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import map from "lodash/map";
import { TabPanel } from "./TabPanel";
import { ActivityItem } from "../ActivityItem";
import { HideOnScroll } from "../HideOnScroll";
import { Box } from "@mui/system";
import { useAppBarHeight } from "../../utils/useAppBarHeight";
import size from "lodash/size";

const TAB_HEIGHT = "48px";

function getDateFromISOWeek(week, year = new Date().getFullYear()) {
  const date = new Date(year, 0, 4);
  date.setDate(date.getDate() - (date.getDay() || 7) + 1 + 7 * (week - 1));

  return date;
}

const formatWeek = (week) => {
  const weekNumber = week.slice(4);
  const weekYear = week.slice(0, 4);

  return getDateFromISOWeek(weekNumber, weekYear).toLocaleDateString("fr", {
    day: "numeric",
    month: "short",
  });
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export const WeekTabs = ({
  membersByActivityItems,
  weeks,
  currentWeek,
  type,
  searchValue,
}) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(weeks.indexOf(currentWeek));
  const appBarHeight = useAppBarHeight();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ mt: TAB_HEIGHT }}>
      <HideOnScroll>
        <AppBar
          sx={{
            top: `${appBarHeight}px`,
            zIndex: theme.zIndex.appBar - 1,
            height: TAB_HEIGHT,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            aria-label="full width tabs example"
          >
            {map(weeks, (week, index) => (
              <Tab key={week} label={formatWeek(week)} {...a11yProps(index)} />
            ))}
          </Tabs>
        </AppBar>
      </HideOnScroll>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {map(weeks, (week, index) => (
          <TabPanel
            key={week}
            value={value}
            index={index}
            dir={theme.direction}
          >
            {map(membersByActivityItems[week], (itemDetails, itemLabel) => (
              <ActivityItem
                key={itemLabel}
                type={type}
                itemLabel={itemLabel}
                itemDetails={itemDetails}
                week={week}
                currentWeek={currentWeek}
                searchValue={searchValue}
                numberOfWeekDays={size(membersByActivityItems[week]) / 2}
              />
            ))}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Box>
  );
};
