import { useCallback, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { throwError } from "../actions";

export const useChecked = (
  initialState,
  callFunction,
  returnEarlyCondition
) => {
  const updateQuery = useRef();
  const [checked, setChecked] = useState(!!initialState);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handleToggle = useCallback(() => {
    if (returnEarlyCondition) return;
    if (updateQuery.current && updateQuery.current.cancel) {
      updateQuery.current.cancel();
    }

    const valueToSend = !checked;
    setChecked(valueToSend);
    queryClient.cancelQueries(["members"]);
    updateQuery.current = callFunction(valueToSend);

    updateQuery.current
      .then(() => {
        queryClient.invalidateQueries("members");
      })
      .catch((error) => {
        error.customMessage = "Impossible de mettre à jour l'adhérent...";
        setChecked(!valueToSend);
        dispatch(throwError(error));
      });
  }, [callFunction, checked, dispatch, queryClient, returnEarlyCondition]);

  return [checked, handleToggle];
};
