import React from "react";
import { Chip, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoneyOffRoundedIcon from "@mui/icons-material/MoneyOffRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import InputRoundedIcon from "@mui/icons-material/InputRounded";

const paramsByStatus = {
  waiting: {
    label: "non payé",
    icon: <MoneyOffRoundedIcon />,
    color: "secondary",
  },
  partiallyPaid: {
    label: "pré-inscrit",
    icon: <LocalAtmRoundedIcon />,
    color: "primary",
  },
  inValidation: {
    label: "paiement en validation",
    icon: <InputRoundedIcon />,
    color: "primary",
  },
  done: {
    label: "adhésion expirée",
    icon: <HighlightOffRoundedIcon />,
    color: "secondary",
  },
};

const useStyles = makeStyles((theme) => ({
  waiting: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
}));

export const PaymentChip = ({ status }) => {
  const classes = useStyles();

  if (!status || status === "paid") {
    return null;
  }

  return (
    <Grid item xs="auto">
      <Chip
        size="small"
        {...paramsByStatus[status]}
        classes={{
          colorPrimary: classes.warning,
          colorSecondary: classes.waiting,
        }}
      />
    </Grid>
  );
};
