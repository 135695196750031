import React, { forwardRef, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import reduce from "lodash/reduce";
import { PrimaryContact } from "./PrimaryContact";
import { SingleContactDetail } from "./SingleContactDetails";
import isEmpty from "lodash/isEmpty";

export const MemberContactDetails = forwardRef(
  ({ member, order, ...rest }, ref) => {
    const parent2 = useMemo(
      () =>
        reduce(
          member,
          (acc, value, key) => {
            if (key.indexOf("parent2-") !== -1) {
              acc[key.replace("parent2-", "")] = value;
            }

            return acc;
          },
          {}
        ),
      [member]
    );

    const primaryContact = order?.billing;

    return (
      <List ref={ref} {...rest}>
        <ListItem>
          <ListItemText
            disableTypography
            primary={
              <PrimaryContact
                hasParent2={!isEmpty(parent2)}
                member={member}
                memberData={primaryContact}
              />
            }
            secondary={
              <SingleContactDetail
                isLoading={!primaryContact}
                displayName={!isEmpty(parent2)}
                lastName={member.parent1_last_name}
                firstName={member.parent1_first_name}
                address={primaryContact?.address_1}
                city={primaryContact?.city}
                postCode={primaryContact?.postcode}
                email={primaryContact?.email}
                phone={primaryContact?.phone}
              />
            }
          />
        </ListItem>
        {!isEmpty(parent2) ? (
          <>
            <Divider variant="middle" />
            <ListItem>
              <ListItemText
                disableTypography
                primary={<Typography>Parent 2</Typography>}
                secondary={
                  <SingleContactDetail
                    displayName
                    lastName={parent2.Nom}
                    firstName={parent2["Prénom"]}
                    address={parent2.Adresse}
                    city={parent2.Ville}
                    postCode={parent2["Code Postal"]}
                    email={parent2.Email}
                    phone={parent2["Tél"]}
                  />
                }
              />
            </ListItem>
          </>
        ) : null}
      </List>
    );
  }
);
