import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { resetError } from "../actions";
import Snackbar from "@mui/material/Snackbar";

const useStyles = makeStyles((theme) => ({
  anchorOriginBottomCenter: {
    bottom: theme.spacing(3),
    left: theme.spacing(1),
    right: theme.spacing(1),
    transform: "none",
  },
}));

const buildErrorMessage = (error = {}) =>
  error.customMessage
    ? error.customMessage
    : "Impossible de réaliser l'action...";

export const ErrorSnackbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector(({ error }) => error);
  const [errorMessage, setErrorMessage] = useState(buildErrorMessage(error));

  useEffect(() => {
    if (error) setErrorMessage(buildErrorMessage(error));
  }, [error]);

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      dispatch(resetError());
    },
    [dispatch]
  );

  return (
    <Snackbar
      open={!!error}
      autoHideDuration={6000}
      onClose={handleClose}
      classes={{
        anchorOriginBottomCenter: classes.anchorOriginBottomCenter,
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="error">
        {errorMessage} Vous pouvez réessayer.
      </Alert>
    </Snackbar>
  );
};
