import { useQuery } from "react-query";
import { fetchCurrentUser } from "./apiFetcher";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";

export const useHasMasterRights = (onlyTopManager, educatorPlusAuthorized) => {
  const { data: user, isFetching } = useQuery(
    ["currentUser"],
    fetchCurrentUser,
    {
      suspense: false,
      staleTime: Infinity,
    }
  );

  if (!user && isFetching) {
    return;
  }

  const masterRoles = ["shop_manager", "administrator"];
  if (!onlyTopManager) {
    masterRoles.push("contributor");
  }

  if (educatorPlusAuthorized) {
    masterRoles.push("educator_plus");
  }

  if (user && !isEmpty(intersection(user.roles, masterRoles))) {
    return true;
  }

  return false;
};
