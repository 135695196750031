import reduce from "lodash/reduce";
import forEach from "lodash/forEach";
import isString from "lodash/isString";
import get from "lodash/get";
import map from "lodash/map";

let NerosRequestHeaders = {};
if (process.env.REACT_APP_AUTHORIZATION_HEADER) {
  NerosRequestHeaders = new Headers({
    Authorization: process.env.REACT_APP_AUTHORIZATION_HEADER,
  });
}

let WCRequestHeaders = {};
if (process.env.REACT_APP_WC_AUTHORIZATION_HEADER) {
  WCRequestHeaders = new Headers({
    Authorization: process.env.REACT_APP_WC_AUTHORIZATION_HEADER,
    "Content-Type": "application/json",
  });
}

let StripeRequestHeaders = {};
if (process.env.REACT_APP_STRIPE_API_KEY) {
  StripeRequestHeaders = new Headers({
    Authorization: process.env.REACT_APP_STRIPE_API_KEY,
    "Content-Type": "application/json",
  });
}

const orderIdRegEx = /(?:Commande|Order) ([0-9]+)(?:-([0-9]))?$/;
const TIMEOUT = 20000;
let nonce;

const fetchApi = ({
  baseUrl,
  headers,
  path,
  method = "GET",
  body,
  tryCount = 0,
}) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = new Promise(async (resolve, reject) => {
    const id = setTimeout(() => {
      controller.abort();

      const error = new Error("Connection timeout");
      error.customMessage = "Impossible de vous connecter...";
      reject(error);
    }, TIMEOUT);

    const response = await fetch(`${baseUrl}${path}`, {
      headers,
      method,
      body,
      signal,
    });

    clearTimeout(id);
    if (response.ok) {
      resolve(response.json());
    }

    if (response.status === 401 || response.status === 403) {
      if (tryCount === 0) {
        tryCount++;
        nonce = await fetchApi({
          baseUrl: process.env.REACT_APP_BASE_URL,
          path: process.env.REACT_APP_NONCE_PATH,
          headers: NerosRequestHeaders,
          tryCount,
        });

        headers.set("X-WP-Nonce", nonce);

        return fetchApi({
          baseUrl,
          headers,
          path,
          method,
          body,
          tryCount,
        });
      }

      document.location = `https://www.cisag.org/wp-login.php?redirect_to=${document.location}`;
    }

    if (response.status >= 400) {
      reject(new Error(`${response.status} HTTP error received.`));
    }
  });

  promise.cancel = () => controller.abort();

  return promise;
};

const fetchNerosApi = async (path, headers, method, body) => {
  const builtHeaders = new Headers(NerosRequestHeaders);

  if (!nonce) {
    nonce = await fetchApi({
      baseUrl: process.env.REACT_APP_BASE_URL,
      path: process.env.REACT_APP_NONCE_PATH,
      headers: NerosRequestHeaders,
    });
  }

  if (nonce) {
    builtHeaders.append("X-WP-Nonce", nonce);
  }

  if (headers) {
    forEach(headers, (value, key) => builtHeaders.append(key, value));
  }

  return fetchApi({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    headers: builtHeaders,
    path,
    method,
    body,
  });
};

const fetchWCApi = (path, method, body) =>
  fetchApi({
    baseUrl: process.env.REACT_APP_WC_API_BASE_URL,
    headers: WCRequestHeaders,
    path,
    method,
    body,
  });

const fetchStripeApi = (path, method, body) =>
  fetchApi({
    baseUrl: process.env.REACT_APP_STRIPE_API_BASE_URL,
    headers: StripeRequestHeaders,
    path,
    method,
    body,
  });

export const fetchMembers = (season, activity, quarter) =>
  isString(activity)
    ? fetchNerosApi(
        `${season}/membersByCourse/${activity}${
          quarter ? `?quarter=${quarter}` : ""
        }`
      )
    : fetchNerosApi(`${season}/members`);
export const fetchMember = (id) => fetchNerosApi(`members/${id}`);
export const fetchMembersByTemporaryActivity = (type, season) =>
  fetchNerosApi(`${season}/membersByTemporaryActivity/${type}`);
export const updateActivityItem = ({ id, locked, time, numberOfDays }) =>
  fetchNerosApi(
    `activityItems/${id}${
      time && numberOfDays ? `?time=${time}&numberOfDays=${numberOfDays}` : ""
    }`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ locked })
  );
export const updateMember = ({ id, courseIds, practice }) =>
  fetchNerosApi(
    `members/${id}${practice ? "?practice=1" : ""}`,
    { "Content-Type": "application/json" },
    "PUT",
    JSON.stringify({ courses: courseIds })
  );

export const setMemberDocument = ({ id, key, handDelivered, index }) =>
  fetchNerosApi(
    `members/${id}/documents/${key}`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ handDelivered, index })
  );

export const setMemberCard = ({ id, requested, name }) =>
  fetchNerosApi(
    `members/${id}/card/${name}`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ requested })
  );

export const setOrderAccessory = ({ id, item, handDelivered }) =>
  fetchNerosApi(
    `orders/${id}/accessories/${item.id}`,
    { "Content-Type": "application/json" },
    "POST",
    JSON.stringify({ handDelivered })
  );

export const fetchCourses = (season, productId) =>
  fetchNerosApi(`${season}/coursesByProduct/${productId}`);

export const fetchOrders = (orderIds) =>
  fetchNerosApi(`orders?ids=${orderIds.join(",")}`);

export const fetchGeneralStats = (season) =>
  fetchNerosApi(`${season}/stats/general`);
export const fetchActivitiesStats = (season) =>
  fetchNerosApi(`${season}/stats/activities`);

export const fetchOrder = (id) => fetchWCApi(`orders/${id}`);
export const updateOrder = ({ id, status }) =>
  fetchWCApi(
    `orders/${id}`,
    "PUT",
    JSON.stringify({
      status,
      payment_method: "cheque",
      payment_method_title: "Chèque, chèques vacances ou espèces",
    })
  );

export const fetchSalesReport = (season, type) =>
  fetchNerosApi(`${season}/stats/sales/${type}`);

export const fetchPayouts = ({ pageParam }) =>
  fetchStripeApi(
    `payouts?limit=50${pageParam ? `&starting_after=${pageParam}` : ""}`,
    "GET"
  );

export const fetchCharge = ({ id }) => fetchStripeApi(`charges/${id}`, "GET");

export const fetchPayoutDetail = async ({ id }) => {
  const transactions = await fetchStripeApi(
    `transfers/${id}/transactions?limit=100`,
    "GET"
  );

  const transactionOrders = await Promise.all(
    map(get(transactions, "data"), async (transaction) => {
      let charge;
      if (transaction.amount < 0) {
        charge = await fetchCharge({ id: transaction.id });
      }

      const orderParsing = get(charge || transaction, "description", "").match(
        orderIdRegEx
      );
      let orderId = parseInt(get(orderParsing, 1), 10);

      if (!orderId) {
        return { transaction };
      }

      const subOrderId = get(orderParsing, 2);

      if (subOrderId) {
        orderId += parseInt(subOrderId, 10);
      }

      return { transaction, orderId };
    })
  );

  const orderIds = map(transactionOrders, ({ orderId }) => orderId);
  const orders = await fetchOrders(orderIds);

  return reduce(
    transactionOrders,
    (allocation, { transaction, orderId }) => {
      if (!transaction) {
        return allocation;
      }

      const type = orders[orderId] || "other";

      allocation[type].raw += parseInt(transaction.amount, 10);
      allocation[type].net += parseInt(transaction.net, 10);
      allocation[type].fee += parseInt(transaction.fee, 10);

      return allocation;
    },
    {
      subscription: { raw: 0, net: 0, fee: 0 },
      training: { raw: 0, net: 0, fee: 0 },
      other: { raw: 0, net: 0, fee: 0 },
    }
  );
};

export const fetchCurrentUser = () => fetchNerosApi("users/current");
