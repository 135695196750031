import React, { useState, useEffect, useReducer } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  AccordionActions,
  Button,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import upperFirst from "lodash/upperFirst";
import findIndex from "lodash/findIndex";
import clone from "lodash/clone";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Skeleton, Alert } from "@mui/material";
import { CourseSelectionModal } from "./CourseSelectionModal";
import { fetchCourses, updateMember } from "../../../utils/apiFetcher";
import { useMutation, useQueryClient } from "react-query";
import { formatCourseTime } from "../../../utils/course";
import { throwError } from "../../../actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    flexBasis: "40%",
    flexShrink: 0,
    marginRight: theme.spacing(1),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  details: {
    fontSize: theme.typography.pxToRem(13),
    fontStyle: "italic",
    color: theme.palette.text.secondary,
  },
  snackbar: {
    right: 0,
    bottom: 0,
    left: 0,
    transform: "none",
  },
  alert: {
    width: "100%",
  },
  alertMessage: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  alertIcon: {
    alignItems: "center",
  },
}));

const CourseAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));

const CourseAccordionSummary = withStyles({
  content: {
    alignItems: "center",
  },
})(AccordionSummary);

const initialState = {
  courses: [],
  initialCourses: [],
  shouldBeApproved: false,
  priceDifference: null,
  inProgress: false,
};

function coursesReducer(state, action) {
  switch (action.type) {
    case "inProgress":
      return { ...state, inProgress: true };
    case "error":
      return { ...state, inProgress: false };
    case "init":
      return {
        ...state,
        courses: action.courses,
        initialCourses: action.courses,
        shouldBeApproved: false,
        priceDifference: null,
      };
    case "cancel":
      return {
        ...state,
        courses: state.initialCourses,
        shouldBeApproved: false,
        priceDifference: null,
      };
    case "modify":
      const courses = clone(state.courses);
      courses.splice(
        findIndex(courses, { id: action.prevCourse.id }),
        1,
        action.nextCourse
      );

      return {
        ...state,
        courses,
        shouldBeApproved: true,
        priceDifference:
          action.prevCourse.allout !== action.nextCourse.allout ? null : 0,
      };
    case "price":
      return {
        ...state,
        priceDifference: action.difference,
      };
    default:
      throw new Error();
  }
}

export const MemberCourseList = ({ member, memberData, setLocalChange }) => {
  const classes = useStyles();
  const [activeCourse, setOpen] = useState(false);
  const [
    { shouldBeApproved, priceDifference, courses, inProgress },
    setCourses,
  ] = useReducer(coursesReducer, initialState);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: approveChange } = useMutation(updateMember, {
    onMutate: () => {
      setCourses({ type: "inProgress" });

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(["member", member.registration_id]);

      // Snapshot the previous value
      const previousMember = queryClient.getQueryData([
        "member",
        member.registration_id,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["member", member.registration_id],
        (oldMember) => {
          oldMember.courses = courses;

          return oldMember;
        }
      );

      // Return the snapshotted value
      return () =>
        queryClient.setQueryData(
          ["member", member.registration_id],
          previousMember
        );
    },
    onSuccess: () => {
      setLocalChange(false);
      setCourses({ type: "init", courses });
      queryClient.invalidateQueries("members");
    },
    onError: (error, variables, rollback) => {
      error.customMessage = "Impossible de mettre à jour l'adhérent...";
      dispatch(throwError(error));
      setCourses({ type: "error" });
      rollback();
    },
  });
  const { season } = useParams();
  const handleClickOpen = (course) => () => {
    setOpen(course);
    const { _product_id: productId } = member;
    queryClient.prefetchQuery(["coursesByProduct", season, productId], () =>
      fetchCourses(season, productId)
    );
  };

  useEffect(() => {
    if (memberData)
      setCourses({
        type: "init",
        courses: memberData.courses,
      });
  }, [memberData]);

  useEffect(() => {
    async function fetchData() {
      if (shouldBeApproved && priceDifference === null) {
        const newPriceDifference = await updateMember({
          id: member.registration_id,
          courseIds: courses.map((course) => course.id),
          practice: true,
        });

        setCourses({ type: "price", difference: newPriceDifference });
      }
    }
    fetchData();
  }, [
    shouldBeApproved,
    courses,
    member.id,
    member.registration_id,
    priceDifference,
  ]);

  const handleClose = (nextCourse) => {
    if (activeCourse && nextCourse && activeCourse.id !== nextCourse.id) {
      setLocalChange(true);
      setCourses({ type: "modify", prevCourse: activeCourse, nextCourse });
    }
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setLocalChange(false);
    setCourses({ type: "cancel" });
  };

  const handleApprove = () =>
    approveChange({
      id: member.registration_id,
      courseIds: courses.map((course) => course.id),
    });

  if (!memberData) {
    return (
      <div className={classes.root}>
        <CourseAccordion>
          <CourseAccordionSummary aria-controls="panel-1" id="panel-1">
            <Typography className={classes.heading}>
              <Skeleton />
            </Typography>
            <Typography className={classes.secondaryHeading}>
              <Skeleton width={150} />
            </Typography>
          </CourseAccordionSummary>
        </CourseAccordion>
      </div>
    );
  }

  let approval = (
    <>
      Calcul de la différence de prix... <CircularProgress size={20} />
    </>
  );

  const approveButton = (
    <Button color="inherit" size="small" onClick={handleApprove}>
      Valider
    </Button>
  );
  if (inProgress) {
    approval = (
      <>
        Enregistrement en cours... <CircularProgress size={20} />
      </>
    );
  } else if (priceDifference === 0) {
    approval = (
      <>
        <div>Prix identique. Enregistrer ?</div>
        {approveButton}
      </>
    );
  } else if (priceDifference > 0) {
    approval = (
      <>
        <div>
          L'adhérent doit <strong>{priceDifference}€</strong>
          {/*. Paiement reçu ?*/}
        </div>
        {/* {approveButton} */}
      </>
    );
  } else if (priceDifference < 0) {
    approval = (
      <>
        <div>
          Le club doit <strong>{-priceDifference}€</strong>
          {/*. Remboursement OK ?*/}
        </div>
        {/* {approveButton} */}
      </>
    );
  }

  return (
    <div className={classes.root}>
      {courses.map((course) => {
        const isAllout = JSON.parse(course.allout);
        let heading = upperFirst(course.day);
        let secondaryHeading = upperFirst(course.activity);

        if (!isAllout) {
          heading += ` ${formatCourseTime(course)}`;

          secondaryHeading += ` ${course.label.replaceAll(" ", "&nbsp")}`;
        }

        return (
          <CourseAccordion key={course.id}>
            <CourseAccordionSummary
              expandIcon={!isAllout && <ExpandMoreRoundedIcon />}
              aria-controls={`panel-${course.id}`}
              id={`panel-${course.id}`}
            >
              <Typography className={classes.heading}>{heading}</Typography>
              <Typography
                className={classes.secondaryHeading}
                dangerouslySetInnerHTML={{
                  __html: secondaryHeading,
                }}
              />
            </CourseAccordionSummary>
            {!isAllout && (
              <AccordionDetails>
                <Typography className={classes.details}>
                  {upperFirst(course.educator1)}
                  {course.educator2 ? ` & ${upperFirst(course.educator2)}` : ""}
                  {" / "}
                  {course.place}
                </Typography>
              </AccordionDetails>
            )}
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="secondary"
                onClick={handleClickOpen(course)}
              >
                Modifier
              </Button>
            </AccordionActions>
          </CourseAccordion>
        );
      })}
      <CourseSelectionModal
        member={member}
        open={!!activeCourse}
        onClose={handleClose}
      />
      {shouldBeApproved && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          className={classes.snackbar}
        >
          <Alert
            action={
              inProgress ? null : (
                <Button
                  color="inherit"
                  size="small"
                  onClick={handleSnackbarClose}
                >
                  Annuler
                </Button>
              )
            }
            severity={
              priceDifference === null || priceDifference === 0
                ? "info"
                : "warning"
            }
            classes={{
              root: classes.alert,
              message: classes.alertMessage,
              icon: classes.alertIcon,
            }}
          >
            {approval}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};
