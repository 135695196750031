import React from "react";
import { TableRow, TableCell } from "@mui/material";
import find from "lodash/find";
import { Frequency } from "./Frequency";

export const RegistrationRow = ({ item }) => {
  const firstName = find(item.meta_data, { key: "Prénom" });
  const lastName = find(item.meta_data, { key: "Nom" });

  return (
    <TableRow key={item.id}>
      <TableCell>
        <div>{item.name}</div>
        {firstName && lastName && (
          <>
            <div>
              {firstName.value} {lastName.value}
            </div>
            <Frequency metadata={item.meta_data} />
          </>
        )}
      </TableCell>
      <TableCell align="right">{item.subtotal}€</TableCell>
    </TableRow>
  );
};
