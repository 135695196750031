import React from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/styles";

const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.grey[300],
  textAlign: "center",
  fontStyle: "italic",
  padding: "15px 0",
}));

export const Message = ({ children }) => (
  <Text component="div">{children}</Text>
);
