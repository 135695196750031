import makeStyles from "@mui/styles/makeStyles";

export const useTableStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    padding: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    alignItems: "center",
    minHeight: "50px",
  },
  tooltip: {
    textAlign: "right",
  },
}));
