import React, { useState, forwardRef, memo, useCallback } from "react";
import { useQuery } from "react-query";
import makeStyles from "@mui/styles/makeStyles";
import { Grow, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { MemberName } from "./MemberName";
import { MemberBottomBar } from "./MemberBottomBar";
import { MemberDetails } from "./details/MemberDetails";
import { MemberContactDetails } from "./contact/MemberContactDetails";
import { fetchMember, fetchOrder } from "../../utils/apiFetcher";
import { MemberFinancialDetails } from "./finances/MemberFinancialDetails";
import { MemberCheckList } from "./checkList/MemberCheckList";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(7),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  content: {
    position: "relative",
    left: 0,
    width: "100%",
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const setRelativePosition = (node) => (node.style.position = "relative");
const setAbsolutePosition = (node) => (node.style.position = "absolute");

export const MemberModal = memo(
  ({ member, isAnnual, isNursery, handleClose }) => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const [hasLocalChange, setLocalChange] = useState(false);
    const open = !!member;

    const memberId = member?.registration_id;
    const { data: memberData } = useQuery(
      ["member", memberId],
      () => fetchMember(memberId),
      { enabled: open, suspense: false, staleTime: Infinity }
    );

    const orderId = Number(member?.order_id);
    const { data: orderData } = useQuery(
      ["order", orderId],
      () => fetchOrder(orderId),
      { enabled: open, suspense: false, staleTime: 60000 }
    );

    const onClose = useCallback(() => {
      if (
        !hasLocalChange ||
        window.confirm(
          "Vous avez des changements non enregsitrés, quitter quand même ?"
        )
      ) {
        handleClose();
        setActiveTab(0);
      }
    }, [handleClose, hasLocalChange]);

    return (
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={onClose}
          TransitionComponent={Transition}
        >
          <div className={classes.container}>
            <AppBar position="sticky">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={onClose}
                  aria-label="close"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
                <MemberName
                  variant="h6"
                  member={member}
                  className={classes.title}
                />
              </Toolbar>
            </AppBar>
            {open ? (
              <Box>
                <Grow
                  in={activeTab === 0}
                  mountOnEnter
                  unmountOnExit
                  onEnter={setAbsolutePosition}
                  onEntered={setRelativePosition}
                  onExit={setAbsolutePosition}
                >
                  <MemberDetails
                    member={member}
                    memberData={memberData}
                    className={classes.content}
                    setLocalChange={setLocalChange}
                    isAnnual={isAnnual}
                  />
                </Grow>
                <Grow
                  in={activeTab === 1}
                  mountOnEnter
                  unmountOnExit
                  onEnter={setAbsolutePosition}
                  onEntered={setRelativePosition}
                  onExit={setAbsolutePosition}
                >
                  <MemberContactDetails
                    member={member}
                    order={orderData}
                    className={classes.content}
                  />
                </Grow>
                <Grow
                  in={activeTab === 2}
                  mountOnEnter
                  unmountOnExit
                  onEnter={setAbsolutePosition}
                  onEntered={setRelativePosition}
                  onExit={setAbsolutePosition}
                >
                  <MemberFinancialDetails
                    order={orderData}
                    className={classes.content}
                  />
                </Grow>
                <Grow
                  in={activeTab === 3}
                  mountOnEnter
                  unmountOnExit
                  onEnter={setAbsolutePosition}
                  onEntered={setRelativePosition}
                  onExit={setAbsolutePosition}
                >
                  <MemberCheckList
                    isNursery={isNursery}
                    member={member}
                    memberData={memberData}
                    className={classes.content}
                  />
                </Grow>
              </Box>
            ) : null}
          </div>
          <MemberBottomBar
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            isAnnual={isAnnual}
            isNursery={isNursery}
            member={member}
          />
        </Dialog>
      </div>
    );
  }
);
