import { List, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import map from "lodash/map";
import React, { forwardRef } from "react";
import {
  documents,
  getDocumentKey,
  isDocumentRequired,
} from "../../../utils/member";
import { SectionTitle } from "../SectionTitle";
import { CheckListItem } from "./CheckListItem";
import { MemberAccessories } from "./MemberAccessories";

const Legend = styled("div")(({ theme }) => ({
  fontStyle: "italic",
  padding: theme.spacing(0, 2),
}));

const generateDocumentsList = (member) => (props, key) => {
  if (props.iterative) {
    const numberOfChildren = parseInt(get(member, "Nombre D'enfants"), 10);
    const iterations = new Array(numberOfChildren).fill(0);

    return iterations.map((value, index) => (
      <CheckListItem
        key={key}
        id={key}
        index={index}
        memberId={member.registration_id}
        handDelivered={member[getDocumentKey(key, index)]}
        label={`${props.label} #${index + 1}`}
        url={get(member, ["files", key, index])}
      />
    ));
  }

  return (
    isDocumentRequired(key, member) && (
      <CheckListItem
        key={key}
        id={key}
        memberId={member.registration_id}
        handDelivered={member[getDocumentKey(key)]}
        label={props.label}
        url={get(member, ["files", key, 0])}
      />
    )
  );
};

export const MemberCheckList = forwardRef(
  ({ member, memberData, isNursery, ...rest }, ref) => {
    return (
      <div ref={ref} {...rest}>
        <SectionTitle>Documents rendus</SectionTitle>
        <List>
          {map(
            documents[isNursery ? "nursery" : "member"],
            generateDocumentsList(member)
          )}
        </List>
        {!isNursery && (
          <>
            <SectionTitle>Accessoires commandés pour le foyer</SectionTitle>
            {!isEmpty(member.accessories) ? (
              <Legend>
                <Typography variant="caption" color="textSecondary">
                  Cochez si distribué
                </Typography>
              </Legend>
            ) : null}
            <MemberAccessories member={member} memberData={memberData} />
          </>
        )}
      </div>
    );
  }
);
