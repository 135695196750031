import React from "react";
import { Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NightShelterRoundedIcon from "@mui/icons-material/NightShelterRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  nursery: {
    color: theme.palette.info.main,
  },
}));

export const NurseryIcon = () => {
  const classes = useStyles();

  return (
    <Tooltip title="garderie" arrow placement="left">
      <NightShelterRoundedIcon
        color="action"
        classes={{
          root: classes.root,
          colorAction: classes.nursery,
        }}
      />
    </Tooltip>
  );
};
