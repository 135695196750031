import React, { useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import map from "lodash/map";
import upperFirst from "lodash/upperFirst";
import { Loader } from "../components/Loader";
import { useQueryClient } from "react-query";
import { fetchMembers } from "../utils/apiFetcher";
import { CoursesTable } from "../components/table/CoursesTable";

const activityMap = {
  trampoline: {
    label: "Trampoline",
    to: "/:season/cours/trampoline",
    apiLabel: "trampoline",
  },
  bambins: {
    label: "Bambins",
    to: "/:season/cours/bambins",
    apiLabel: "bambins à ressorts",
  },
  fitness: {
    label: "Fitness",
    to: "/:season/cours/fitness",
    apiLabel: "fitness",
  },
  gymnastique: {
    label: "Gymnastique",
    to: "/:season/cours/gymnastique",
    apiLabel: "gymnastique",
  },
  ecole_acro: {
    label: "École acro",
    to: "/:season/cours/ecole_acro",
    apiLabel: "école acrobatique",
  },
};

export const Courses = ({ setMoreMenu, setPageTitle }) => {
  const { activity, season } = useParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleMenuClick = (activity) => () => {
      queryClient.prefetchQuery(["members", season, activity], () =>
        fetchMembers(season, activity)
      );
    };
    setPageTitle(`Cours - ${upperFirst(activityMap[activity].apiLabel)}`);
    setMoreMenu(
      map(activityMap, (activityMenu) => ({
        ...activityMenu,
        to: activityMenu.to.replace(":season", season),
        handleClick: handleMenuClick(activityMenu.apiLabel),
      }))
    );

    return () => setMoreMenu(null);
  }, [activity, queryClient, season, setMoreMenu, setPageTitle]);

  return (
    <Suspense fallback={<Loader />}>
      <CoursesTable activity={activityMap[activity].apiLabel} />
    </Suspense>
  );
};
