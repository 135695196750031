import React from "react";
import { Chip, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FiberNewRoundedIcon from "@mui/icons-material/FiberNewRounded";

const useStyles = makeStyles((theme) => ({
  new: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
}));

export const NewMemberChip = ({ isNew }) => {
  const classes = useStyles();

  if (!isNew) {
    return null;
  }

  return (
    <Grid item xs="auto">
      <Chip
        size="small"
        label="nouvel adhérent"
        color="primary"
        icon={<FiberNewRoundedIcon />}
        classes={{
          colorPrimary: classes.new,
        }}
      />
    </Grid>
  );
};
