import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import SwapHorizontalCircleRoundedIcon from "@mui/icons-material/SwapHorizontalCircleRounded";

const useStyles = makeStyles((theme) => ({
  notPaid: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
  },
  paid: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(0.5),
  },
  onValidation: {
    color: theme.palette.warning.main,
    marginLeft: theme.spacing(0.5),
  },
}));

export const PaymentIcon = ({ status }) => {
  const classes = useStyles();

  if (
    !status ||
    status === "pending" ||
    status === "waiting" ||
    status === "on-hold" ||
    status === "failed"
  ) {
    return <CancelRoundedIcon fontSize="small" className={classes.notPaid} />;
  } else if (status === "on-validation" || status === "inValidation") {
    return (
      <SwapHorizontalCircleRoundedIcon
        fontSize="small"
        className={classes.onValidation}
      />
    );
  }

  return <CheckCircleRoundedIcon fontSize="small" className={classes.paid} />;
};
