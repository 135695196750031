import React from "react";
import ChildCareRoundedIcon from "@mui/icons-material/ChildCareRounded";
import AccessibilityNewRoundedIcon from "@mui/icons-material/AccessibilityNewRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import ChangeHistoryRoundedIcon from "@mui/icons-material/ChangeHistoryRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";

export const formatCourseTime = (course) =>
  `${course.start_time?.replace("00", "")}-${course.end_time?.replace(
    "00",
    ""
  )}`;

export const getActivityIcon = (activity) => {
  switch (activity.toLowerCase()) {
    case "école acrobatique":
      return <SchoolRoundedIcon />;
    case "trampoline":
      return <AccessibilityNewRoundedIcon />;
    case "gymnastique":
      return <ChangeHistoryRoundedIcon />;
    case "fitness":
      return <FitnessCenterRoundedIcon />;
    default:
      return <ChildCareRoundedIcon />;
  }
};
