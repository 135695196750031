import React, { useEffect, Suspense } from "react";
import { useParams, Redirect } from "react-router-dom";
import { AccountingTable } from "../components/accounting/AccountingTable";
import { Loader } from "../components/Loader";
import { useHasMasterRights } from "../utils/useHasMasterRights";

export const Accounting = ({ setPageTitle }) => {
  let { activity } = useParams();

  const hasMasterRights = useHasMasterRights(true);

  useEffect(() => {
    setPageTitle("Virements CB");
  }, [activity, setPageTitle]);

  if (hasMasterRights === undefined) {
    return <Loader />;
  }

  return hasMasterRights ? (
    <Suspense fallback={<Loader />}>
      <AccountingTable />
    </Suspense>
  ) : (
    <Redirect to="/" />
  );
};
