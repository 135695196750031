import React from "react";
import map from "lodash/map";
import keys from "lodash/keys";
import values from "lodash/values";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchSalesReport } from "../../utils/apiFetcher";
import { Box } from "@mui/material";
import { addAlpha } from "../../utils/styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const currencyFormatter = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const options = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1,
  onResize: () => console.log("resize"),
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value) =>
          value >= 1000 && value % 1000 === 0 ? `${value / 1000}K` : value,
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Chiffre d'affaires brut des stages (€)",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += currencyFormatter.format(context.parsed.y);
          }
          return label;
        },
      },
    },
  },
};

const chartConfig = {
  trampocamp: {
    label: "Trampoline",
    backgroundColor: ({ chart }) => {
      const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, addAlpha("#21CBF3", 0.8));
      gradient.addColorStop(1, addAlpha("#2196F3", 0.8));

      return gradient;
    },
  },
  bambinscamp: {
    label: "Bambins",
    backgroundColor: ({ chart }) => {
      const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, addAlpha("#ea80ff", 0.8));
      gradient.addColorStop(1, addAlpha("#cc5adc", 0.8));

      return gradient;
    },
  },
};

export const CampFinancialReport = () => {
  const { season } = useParams();
  const { data } = useQuery(["campSales", season], async () => {
    const sales = await fetchSalesReport(season, "camp");

    return {
      labels: keys(sales.trampocamp),
      datasets: map(sales, (revenues, campId) => ({
        label: chartConfig[campId].label,
        data: values(revenues),
        backgroundColor: chartConfig[campId].backgroundColor,
        borderWidth: 0,
        borderRadius: 5,
      })),
    };
  });

  return (
    <Box m={1}>
      <Bar options={options} data={data} />
    </Box>
  );
};
