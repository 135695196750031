import React from "react";
import isEmpty from "lodash/isEmpty";
import { TableRow, TableCell } from "@mui/material";

export const BillAdditionalRow = ({
  items,
  labelKey,
  amountKey,
  toSubtract = false,
  labelPrefix = "",
}) =>
  !isEmpty(items) &&
  items.map((item) => (
    <TableRow key={item.id}>
      <TableCell size="small" align="right">
        {labelPrefix ? `${labelPrefix} ` : ""}
        {item[labelKey]}
      </TableCell>
      <TableCell size="small" align="right">
        {toSubtract && "-"}
        {Number(item[amountKey]).toFixed(2)}€
      </TableCell>
    </TableRow>
  ));
