import React from "react";
import { hasDeposit } from "../../../utils/order";
import { PaymentCard } from "./PaymentCard";
import { DepositPayment } from "./DepositPayment";
import { Grid } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 1, 1),
  },
}));

export const Payment = ({ order }) => {
  const classes = useStyles();
  const isPreRegistration = hasDeposit(order);

  let payment = <PaymentCard order={order} />;

  if (isPreRegistration) {
    payment = <DepositPayment order={order} />;
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        {payment}
      </Grid>
    </div>
  );
};
