import React from "react";
import { Paper, Typography, CircularProgress, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import LinearProgress from "@mui/material/LinearProgress";
import isNil from "lodash/isNil";

const useStyles = makeStyles((theme) => ({
  value: { fontWeight: "bold" },
  paper: {
    padding: theme.spacing(2, 1),
    textAlign: "center",
    background: (props) => theme.palette.background[props.background],
    color: theme.palette.text.secondary,
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props) => theme.palette.progress[props.background],
  },
}))(LinearProgress);

export const StatPaper = ({
  bgcolor,
  icon,
  value,
  label,
  progressValue,
  progressLabel,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <Paper className={classes.paper} {...props}>
      {!isNil(value) ? (
        <>
          <Typography className={classes.value} variant="h3">
            {value}
          </Typography>
        </>
      ) : (
        <CircularProgress color="inherit" />
      )}
      <Typography variant="subtitle2">{label}</Typography>
      {icon ? <Box>{icon}</Box> : null}
      {progressValue ? (
        <Box display="flex" alignItems="center">
          <Box flex={1} mr={1}>
            <BorderLinearProgress
              background={props.background}
              variant="determinate"
              value={progressValue}
            />
          </Box>
          {progressLabel ? (
            <Box>
              <Typography variant="caption" color="textSecondary">
                {progressLabel}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : (
        ""
      )}
    </Paper>
  );
};
