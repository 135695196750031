import map from "lodash/map";
import React, { useEffect, Suspense } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Loader } from "../components/Loader";
import { ActivitiesTable } from "../components/table/ActivitiesTable";
import { fetchMembersByTemporaryActivity } from "../utils/apiFetcher";

const activityMap = {
  trampocamp: {
    label: "Trampoline",
    shortLabel: "TR",
    to: "/:season/stages/trampocamp",
  },
  bambinscamp: {
    label: "Bambins",
    shortLabel: "BB",
    to: "/:season/stages/bambinscamp",
  },
  gymcamp: {
    label: "Gymnastique",
    shortLabel: "GYM",
    to: "/:season/stages/gymcamp",
  },
};

export const HolidayCamp = ({
  setMoreMenu,
  setPageTitle,
  enableSearch,
  searchValue,
}) => {
  const { activity, season } = useParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleMenuClick = (type) => () => {
      queryClient.prefetchQuery(
        ["membersByTemporaryActivity", type, season],
        () => fetchMembersByTemporaryActivity(type, season)
      );
    };

    enableSearch(true);
    setPageTitle(`Stages - ${activityMap[activity].shortLabel}`);
    setMoreMenu(
      map(activityMap, (activityMenu, apiLabel) => ({
        ...activityMenu,
        to: activityMenu.to.replace(":season", season),
        handleClick: handleMenuClick(apiLabel),
      }))
    );

    return () => enableSearch(false);
  }, [activity, queryClient, season, setMoreMenu, setPageTitle, enableSearch]);

  return (
    <Suspense fallback={<Loader />}>
      <ActivitiesTable type={activity} searchValue={searchValue} />
    </Suspense>
  );
};
