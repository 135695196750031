import React from "react";
import map from "lodash/map";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  capitalize,
  styled,
} from "@mui/material";
import get from "lodash/get";
import values from "lodash/values";
import filter from "lodash/filter";
import size from "lodash/size";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import PlaceTwoToneIcon from "@mui/icons-material/PlaceTwoTone";
import ChildCareRoundedIcon from "@mui/icons-material/ChildCareRounded";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import SportsGymnasticsOutlinedIcon from "@mui/icons-material/SportsGymnasticsOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import AccessibilityNewOutlinedIcon from "@mui/icons-material/AccessibilityNewOutlined";

const StyledTableContainer = styled(TableContainer)({
  borderRadius: "0 0 10px 10px",
  padding: "10px 10px 0 0",
});

const StyledTableCell = styled(TableCell)({
  padding: "6px",
  fontSize: "0.95rem",
});

const parametersByActivity = {
  gymnastique: {
    name: "gym acrobatique",
    icon: <SportsGymnasticsOutlinedIcon />,
  },
  trampoline: {
    icon: <AccessibilityNewOutlinedIcon />,
  },
  "école acrobatique": {
    icon: <SchoolOutlinedIcon />,
  },
  "parent-enfant": {
    icon: <ChildFriendlyOutlinedIcon />,
  },
  "bambins à ressorts": {
    icon: <ChildCareRoundedIcon />,
  },
  fitness: {
    icon: <FitnessCenterOutlinedIcon />,
  },
};

export const ScheduleByActivity = ({ activityCourses, days, color }) => {
  return (
    <>
      {map(activityCourses, (courses, label) => {
        let typeIndex = 0;

        return (
          <Box key={label} m={2}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  backgroundColor: color,
                  borderRadius: "30% 10% 0 0/30%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    padding: "5px 5px 0 15px",
                  }}
                >
                  {get(
                    parametersByActivity,
                    `${label.toLowerCase()}.icon`,
                    null
                  )}
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    fontSize="1.1rem"
                    style={{
                      padding: "5px 25px 0 0",
                    }}
                  >
                    {capitalize(
                      get(parametersByActivity, `${label}.name`, label)
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: "1 50%",
                }}
              ></Box>
              <Box
                sx={{
                  marginTop: "12px",
                }}
              >
                <Box
                  sx={{
                    textAlign: "right",
                    backgroundColor: "#eee",
                    borderRadius: "10px 10px 0 0",
                    display: "flex",
                    padding: "5px 10px 2px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      paddingRight: "5px",
                      opacity: 0.7,
                    }}
                  >
                    <PlaceTwoToneIcon />
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {get(values(courses), "0.0.place", "")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <StyledTableContainer
              style={{
                backgroundColor: color,
              }}
            >
              <Table
                sx={{
                  minWidth: 650,
                  borderCollapse: "separate",
                  borderSpacing: "5px 0",
                }}
              >
                <colgroup>
                  <col />
                  <col
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.3)",
                      opacity: 0.5,
                    }}
                    span="6"
                  />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        width: "14%",
                        border: 0,
                      }}
                      key="courseType"
                    ></StyledTableCell>
                    {map(days, (day) => {
                      return (
                        <StyledTableCell
                          key={day}
                          style={{
                            borderRadius: "50% 30% 0% 0%",
                            width: `${86 / days.length}%`,
                            textAlign: "center",
                            textTransform: "uppercase",
                            fontStyle: "italic",
                            paddingLeft: "0",
                          }}
                        >
                          {day}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(courses, (coursesByDay, type) => {
                    typeIndex++;
                    return (
                      <TableRow key={type}>
                        <StyledTableCell
                          key="courseType"
                          style={{
                            border: 0,
                            textAlign: "right",
                            fontWeight: "bold",
                          }}
                        >
                          {type}
                        </StyledTableCell>
                        {map(days, (day) => {
                          const courseByDay = filter(
                            coursesByDay,
                            (course) => course.day === day
                          );

                          if (courseByDay) {
                            return (
                              <StyledTableCell
                                key={day}
                                style={{
                                  borderWidth:
                                    typeIndex === size(courses) ? 0 : "1px",
                                }}
                              >
                                {map(courseByDay, (course) => (
                                  <Box
                                    key={course.start_time}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        paddingRight: "5px",
                                        opacity: 0.7,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <AccessTimeTwoToneIcon fontSize="small" />
                                    </Box>
                                    <Box>
                                      {course.start_time}&nbsp;-&nbsp;
                                      {course.end_time}
                                    </Box>
                                  </Box>
                                ))}
                              </StyledTableCell>
                            );
                          } else {
                            return (
                              <StyledTableCell
                                key={day}
                                style={{
                                  borderWidth:
                                    typeIndex === size(courses) ? 0 : "1px",
                                }}
                              ></StyledTableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Box>
        );
      })}
    </>
  );
};
