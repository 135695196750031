import React, { useEffect, Suspense, useState } from "react";
import { Loader } from "../components/Loader";
import { MemberSearchResult } from "../components/member/MemberSearchResult";

export const Members = ({
  setPageTitle,
  enableSearch,
  searchValue,
  setFilters,
}) => {
  const [notPaidFilter, setNotPaidFilter] = useState(false);
  const [preRegistredFilter, setPreRegistredFilter] = useState(false);
  const [inValidationFilter, setInValidationFilter] = useState(false);
  const [newMemberFilter, setNewMemberFilter] = useState(false);
  const [competitorFilter, setCompetitorFilter] = useState(false);
  const [regionalCardFilter, setRegionalCardFilter] = useState(false);
  const [governmentaCardFilter, setGovernmentalCardFilter] = useState(false);
  const [accessoriesFilter, setAccessoriesFilter] = useState(false);
  const [cardFilter, setCardFilter] = useState(false);

  useEffect(() => {
    setPageTitle("Adhérents");
    enableSearch(true);
    setFilters([
      {
        name: "notpaid",
        label: "non payées",
        handle: setNotPaidFilter,
      },
      {
        name: "preregistred",
        label: "pré-inscrits",
        handle: setPreRegistredFilter,
      },
      {
        name: "invalidation",
        label: "paiement en validation",
        handle: setInValidationFilter,
      },
      {
        name: "new",
        label: "nouveaux",
        handle: setNewMemberFilter,
      },
      {
        name: "competitor",
        label: "compétiteurs",
        handle: setCompetitorFilter,
      },
      {
        name: "regionalCard",
        label: "cartes région",
        handle: setRegionalCardFilter,
      },
      {
        name: "governmentalCard",
        label: "pass'sport",
        handle: setGovernmentalCardFilter,
      },
      {
        name: "federationCard",
        label: "licences à demander",
        handle: setCardFilter,
      },
      {
        name: "accessories",
        label: "accessoires",
        handle: setAccessoriesFilter,
      },
    ]);

    return () => {
      setFilters([]);
      enableSearch(false);
    };
  }, [
    enableSearch,
    setFilters,
    setPageTitle,
    setNotPaidFilter,
    setPreRegistredFilter,
    setNewMemberFilter,
    setCompetitorFilter,
    setCardFilter,
    setInValidationFilter,
    setGovernmentalCardFilter,
    setRegionalCardFilter,
  ]);

  return (
    <Suspense fallback={<Loader />}>
      <MemberSearchResult
        searchValue={searchValue}
        notPaidFilter={notPaidFilter}
        preRegistredFilter={preRegistredFilter}
        inValidationFilter={inValidationFilter}
        newMemberFilter={newMemberFilter}
        competitorFilter={competitorFilter}
        regionalCardFilter={regionalCardFilter}
        governmentalCardFilter={governmentaCardFilter}
        accessoriesFilter={accessoriesFilter}
        cardFilter={cardFilter}
      />
    </Suspense>
  );
};
