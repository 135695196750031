import React, { useState } from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { Message } from "../Message";
import { fetchMembersByTemporaryActivity } from "../../utils/apiFetcher";
import { useQuery } from "react-query";
import { ActivityItem } from "../ActivityItem";
import { useParams } from "react-router-dom";
import keys from "lodash/keys";
import find from "lodash/find";
import getWeek from "date-fns/getWeek";
import fr from "date-fns/locale/fr";
import min from "lodash/min";
import max from "lodash/max";
import toNumber from "lodash/toNumber";
import { getCurrentSeason } from "../../utils/season";
import padStart from "lodash/padStart";
import { WeekTabs } from "../tabs/WeekTabs";

const currentSeason = getCurrentSeason();

export const ActivitiesTable = ({ type, searchValue }) => {
  const { season } = useParams();
  const [currentWeek, setCurrentWeek] = useState(false);

  const { data: membersByActivityItems } = useQuery(
    ["membersByTemporaryActivity", type, season],
    () => fetchMembersByTemporaryActivity(type, season)
  );

  const [previousMembers, setPreviousMembers] = useState(null);

  const weeks = keys(membersByActivityItems);

  if (membersByActivityItems !== previousMembers) {
    setPreviousMembers(membersByActivityItems);

    if (!isNaN(toNumber(weeks[0]))) {
      const todayWeek = getWeek(new Date(), { locale: fr });
      const todayYear = new Date().getFullYear();

      let newWeek = max(weeks);
      if (season === String(currentSeason)) {
        newWeek =
          find(
            weeks,
            (week) => week >= `${todayYear}${padStart(todayWeek, 2, "0")}`
          ) || newWeek;
      } else if (season > currentSeason) {
        newWeek = min(weeks);
      }

      setCurrentWeek(newWeek);
    }
  }

  if (!membersByActivityItems || isEmpty(membersByActivityItems)) {
    return <Message>Aucune activité...</Message>;
  }

  return (
    <>
      {currentWeek && (
        <WeekTabs
          weeks={weeks}
          membersByActivityItems={membersByActivityItems}
          currentWeek={currentWeek}
          searchValue={searchValue}
          type={type}
        />
      )}
      {!currentWeek &&
        map(membersByActivityItems, (itemDetails, itemLabel) => (
          <ActivityItem
            key={itemLabel}
            type={type}
            itemLabel={itemLabel}
            itemDetails={itemDetails}
          />
        ))}
    </>
  );
};
