import React from "react";
import { Name } from "../../Name";
import { Skeleton, Typography } from "@mui/material";

export const PrimaryContact = ({ hasParent2, member, memberData }) => {
  if (hasParent2) {
    return <Typography>Parent 1</Typography>;
  } else if (member.parent1_first_name) {
    return (
      <Name
        firstName={member.parent1_first_name}
        lastName={member.parent1_last_name}
      />
    );
  } else if (memberData && memberData.first_name) {
    return (
      <Name firstName={memberData.first_name} lastName={memberData.last_name} />
    );
  } else {
    return <Skeleton width={150} />;
  }
};
